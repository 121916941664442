import AxiosRequest from "./AxiosRequest";

export const uploadImageToAzure = async (imageFile) => {
  const sasUrl = await generateSasUrl(imageFile.name.replace(/ /g, "_"));
  const headers = new Headers();
  headers.append("x-ms-blob-type", "BlockBlob");
  headers.append("Content-Type", imageFile.type);

  const response = await fetch(sasUrl, {
    method: "PUT",
    headers: headers,
    body: imageFile,
  });

  if (response.ok) {
    return sasUrl.split("?")[0];
  } else {
    return "";
  }
};

const generateSasUrl = async (fileName) => {
  const { data } = await AxiosRequest.post("admin/access-signature", {
    fileName: fileName,
  });
  return data.data.blobUrl;
};
