import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    authToken: null,
    user: null,
    userList: {
      loading: false,
      current: 0,
      from: 0,
      pages: 0,
      records: [],
      to: 0,
      total: 0,
    },
  },
  reducers: {
    userAuthState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { userAuthState } = userSlice.actions;

export default userSlice.reducer;
