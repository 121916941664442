import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const authToken = localStorage.getItem("authToken");
  const user = localStorage.getItem("user");

  if (authToken === null || user === null) {
    return <Navigate to="/" />;
  }
  return children;
};
