import React, { Suspense } from "react";
import routes from "./routes";
import { useRoutes } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  const routing = useRoutes(routes);
  return (
    <>
      <ToastContainer />
      <Suspense fallback={<PageLoader />}>{routing}</Suspense>
    </>
  );
};

const PageLoader = () => {
  return (
    <div className="full-screen-loader">
      <div className="text-center w-100">
        <Spinner animation="border" className="me-2" />
      </div>
    </div>
  );
};

export default App;
