import React, { useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import CommonTable from "../../components/common/Table";
import { useDebounce } from "../../hooks/useDebounce";
import { useRef } from "react";
import { useCallback } from "react";
import AxiosRequest from "../../Utils/AxiosRequest";
import BreadCrumb from "../../components/common/BreadCrumb";
import { toast } from "react-toastify";

const ReactivationRequest = () => {
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const debounceSearch = useDebounce(searchText, 500);
  const fetchIdRef = useRef(0);
  const sortIdRef = React.useRef(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const toggleRef = React.useRef(false);

  const [pageData, setPageData] = useState({
    pageSize: 10,
    pageIndex: 0,
    sortBy: [],
  });

  const handleReactivateUser = (data) => {
    try {
      Swal.fire({
        icon: "warning",
        title: "Are you sure want to Reactivate this user?",
        confirmButtonText: "Yes",
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await AxiosRequest.post(
            `/admin/user/revoke/${data?._id}`
          );
          fetchData(pageData);
          if (response.data.status) {
            Swal.fire({
              icon: "success",
              title: "user has been ReActivate successfully!",
              confirmButtonColor: "#db6574",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error in ReActivate user. Please try again!",
              confirmButtonColor: "#db6574",
            });
          }
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      const sortId = ++sortIdRef.current;
      const fetchId = ++fetchIdRef.current;
      const sortColumn = sortBy[0]?.id;
      const sortOrder = sortBy[0]?.desc ? "desc" : "asc";
      setLoading(true);
      if (searchText) {
        pageIndex = 0;
      }
      setPageData({
        pageSize: pageSize,
        pageIndex: pageIndex,
        sortBy: sortBy,
      });

      if (fetchId === fetchIdRef.current) {
        const res = await AxiosRequest(
          `/admin/user/revoke-request-list?limit=${pageSize}&page=${
            pageIndex + 1
          }&search=${searchText}&sortBy=${sortColumn}&sortAt=${sortOrder}`
        );
        setData(res?.data?.data?.data || []);
        setPageCount(Math.ceil(res?.data?.data.totalCount / pageSize));
        setLoading(false);
      }
    },
    [debounceSearch]
  );

  const columns = React.useMemo(() => [
    {
      Header: "#",
      Cell: ({ row }) => {
        return row.index + 1;
      },
    },
    // {
    //   Header: "Image",
    //   Cell: ({ row }) => {
    //     return (
    //       <img
    //         src="https://i.ibb.co/WtsvWbx/Travis-Kelce-goat.png"
    //         className="rounded-circle img-fluid"
    //         alt="woman avatar"
    //         style={{
    //           width: "40px",
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      sorting: true,
      Header: "Name",
      accessor: "name",
    },
    {
      sorting: true,
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => {
        return row?.original?.email ? row?.original?.email : "-";
      },
    },
    {
      sorting: false,
      Header: "Mobile Number",
      accessor: "mobileNumber",
      Cell: ({ row }) => {
        return row?.original?.mobileNumber
          ? row?.original?.mobileNumber
          : "-";
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-start">
            <button
              className="btn btn-sm btn-primary ms-3"
              onClick={() => {
                handleReactivateUser(row?.original);
              }}
            >
              Reactivate
            </button>
          </div>
        );
      },
    },
  ]);

  return (
    <div className="page-content form-page">
      <div className="bg-dash-dark-2 py-4">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <h3 className="mb-0">Reactivation Requests</h3>
        </div>
      </div>

      <BreadCrumb page="Reactivation Requests" />

      <section className="tables py-0">
        <div className="container-fluid">
          <div className="row flex item-center">
            <div className="col-md-4">
              <label className="form-label pt-2">&nbsp;&nbsp;</label>
              <input
                type="text"
                placeholder="Search"
                className="form-control "
                aria-label="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  toggleRef.current = true;
                }}
              />
            </div>
          </div>
          <div className="row gy-4 mt-2">
            <div className="col-lg-12">
              <div className="card mb-0">
                <div className="card-header"></div>
                <div className="card-body pt-0">
                  <div className="table-responsive">
                    <CommonTable
                      columns={columns}
                      data={data}
                      fetchData={fetchData}
                      loading={loading}
                      pageCount={pageCount}
                      manualPagination={true}
                      manualSort={true}
                      // defaultSortColumn={"name"}
                      isSelection={false}
                      toggleRef={toggleRef}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReactivationRequest;
