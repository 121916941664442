import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Container, Row, Spinner } from "react-bootstrap";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete, MdOutlineAddCircleOutline } from "react-icons/md";
import { LuImport } from "react-icons/lu";
import QuizQuestionModal from "./QuizQuestionModel";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { useDebounce } from "../../hooks/useDebounce";
import { useCallback } from "react";
import CommonTable from "../common/Table";
import { createQuestions } from "../../store/quiz/quiz.fetch";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { quizState } from "../../store/quiz/quiz.slice";
import { BsCloudDownloadFill } from "react-icons/bs";
import ErrorMessageForQuestion from "./ErrorMessageForQuestion";


function isUniqueQuestion(newQuestion, existingQuestions) {
	const newOptionsString = [...newQuestion.options].sort().join("|");

	return !existingQuestions.some((existing) => {
		const existingOptionsString = [...existing.options].sort().join("|");
		const isSameTypeAndText =
			existing.type?.trim() === newQuestion.type?.trim() &&
			existing.question?.trim() === newQuestion.question?.trim();

		const isSameOptions = existingOptionsString === newOptionsString;
		const isSameCorrectAnswer = existing.correctAnswer?.trim() === newQuestion.correctAnswer?.trim();

		const isSameComplexityAndTime = existing.complexity?.trim() === newQuestion.complexity?.trim() && existing.time === newQuestion.time;

		const isSameURL = newQuestion.type !== "VIS" || existing.url === newQuestion.url;

		return (
			isSameTypeAndText &&
			isSameOptions &&
			isSameCorrectAnswer &&
			isSameComplexityAndTime &&
			isSameURL
		);
	});
}


const QuizQuestion = ({
	handleNext = { handleNext },
	handlePrevious = { handlePrevious },
}) => {
	const [showQuizModel, setShowQuizModel] = useState(false);
	const [showEditQuizModel, setShowEditQuizModel] = useState(false);
	const [editQuestionData, setEditQuestionData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isDataExtracting, setIsDataExtracting] = useState(false);
	const [searchText, setSearchText] = useState("");

	const debounceSearch = useDebounce(searchText, 500);
	const fetchIdRef = useRef(0);
	const sortIdRef = React.useRef(0);
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(0);
	const toggleRef = React.useRef(false);
	const [totalQuestions, setTotalQuestions] = useState([]);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();
	const { quizDetail, rounds, questions } = useSelector(
		(state) => state.quiz
	);
	// const [data, setData] = useState(questions);
	const [totalRoundQue, setTotalRoundQues] = useState(0);
	const navigate = useNavigate();

	const [pageData, setPageData] = useState({
		pageSize: 10,
		pageIndex: 0,
		sortBy: [],
	});

	// Error messages while import questions
	const [questionErrorModalOpen, setQuestionErrorModalOpen] = useState(false);
	const [errorMessages, setErrorMessages] = useState([]);
	const [questionLoader ,setQuestionsLoder] = useState(false)

	// console.log("rounds", rounds);
	useEffect(() => {
		setTotalQuestions(questions);
		setData(questions);
	}, [questions.length]);

	useEffect(() => {
		let count = 0;
		rounds.forEach((round) => {
			if (round.totalQuestions) {
				count = count + round.totalQuestions;
			} else {
				count = count + round.noofquestion;
			}
		});
		setTotalRoundQues(count);
		// console.log("COUNT", count);
	}, [rounds.length]);

	const handleSubmit = async () => {
		if (data.length < totalRoundQue) {
			Swal.fire({
				icon: "error",
				title: `<p>You need to add minimum ${totalRoundQue} questions to finish the quiz.</p>`,
				confirmButtonColor: "#8d65db",
			});
			return false;
		}
		setIsLoading(true);
		const _totalQuestions = data.map(({ _id, ...question }) => {
			return question;
		});
		dispatch(
			createQuestions(
				{
					quizId: quizDetail._id,
					questions: _totalQuestions,
				},
				setIsLoading,
				navigate
			)
		);
		// handleNext();
	};

	const handleDelete = async (index) => {
		Swal.fire({
			icon: "error",
			title: "Are you sure want to delete?",
			showCancelButton: true,
			showCloseButton: true,
			confirmButtonColor: "#dc3545",
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setTotalQuestions((prevState) => {
					return prevState.filter((item) => item._id !== index);
				});
				setData((prevState) => {
					return prevState.filter((item) => item._id !== index);
				});
				Swal.fire({
					icon: "success",
					title: "Question has been deleted successfully!",
					confirmButtonColor: "#8d65db",
				});
			}
		});
	};

	const handleEdit = async (data) => {
		try {
			setShowEditQuizModel(true);
			setEditQuestionData(data);
		} catch (err) {
			console.log("err", err);
		}
	};
	const fileInputRef = useRef(null);

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	// console.log("totalQuestions", totalQuestions);

	const handleFileChange = async (event) => {
		setIsDataExtracting(true);
		setQuestionsLoder(true)
		const file = event.target.files[0];
		if (
			file &&
			file.type ===
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		) {
			const file = event.target.files[0];
			const reader = new FileReader();

			reader.onload = function (e) {
				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, { type: "array" });
				const worksheet = workbook.Sheets[workbook.SheetNames[0]];
				const jsonData = XLSX.utils.sheet_to_json(worksheet, {
					header: 1,
				});

				const filteredData = jsonData.filter((row) =>
					row.some((cell) => cell !== "")
				);

				const header = filteredData[0];
				const rowData = filteredData.slice(1);

				const formattedData = rowData.map((row) =>
					header.reduce((obj, key, index) => {
						obj[String(key).trim()] = row[index];
						return obj;
					}, {})
				);

				const optionArr = ["A", "B", "C", "D"];

				const errors = formattedData
					.map((data, index) => {
						const objKeys = Object.keys(data);
						let _errors;
						objKeys?.forEach((key) => {
							if (
								key === "Image" &&
								data["TYPE"] === "VIS" &&
								!data["Image"]
							) {
								_errors = { key, row: index + 1 };
							} else if (!data[key] && key !== "Image") {
								_errors = { key, row: index + 1 };
							}
						});
						return _errors;
					})
					.filter((data) => data);

				if (errors.length > 0) {
					let errorMsg = "";
					errors.forEach((err) => {
						const message = optionArr.includes(err?.key)
							? `Option ${err?.key}`
							: err.key;
						errorMsg =
							errorMsg +
							`Missing value of ${message} in Row ${err.row} <br/>`;
					});
					Swal.fire({
						title: "Oops! Please upload valid excel file",
						icon: "error",
						html: `<p>${errorMsg}</p>`,
						cancelButtonText: "Ok",
						cancelButtonColor: "#8d65db",
					});
					setIsDataExtracting(false);
					return;
				}
				extractData(formattedData, event);
				setIsDataExtracting(false);
				setQuestionsLoder(false)
			};
			reader.readAsArrayBuffer(file);
		} else {
			toast.error("Invalid file format. Please select an XLSX file.");
			setIsDataExtracting(false);
		}
	};

	const extractData = async (data, event) => {
		const expectedTypes = ['VIS', 'TXT']; 
		const expectedComplexities = ["V", "NE", "E", "T"];
		let errorMessages = [];

		const formattedData = data.map((item, index) => {
			const type = item["TYPE"]?.trim();
			const complexity = item["Complexity"]?.trim();
			const question = item["QUESTION"]?.trim();
			const options = [item["A"], item["B"], item["C"], item["D"]]?.map(option => option ? String(option).trim() : null);
			const correctAnswer = item["Correct Answer"] ? String(item["Correct Answer"]).trim() : '';
			// const options = [item["A"], item["B"], item["C"], item["D"]]?.map(option => option?.trim());
  			// const correctAnswer = item["Correct Answer"]?.trim();

			
			if (!options.includes(correctAnswer)) {
				errorMessages.push(`Question "${question}" (Row ${index + 1}): Incorrect 'correctAnswer' value. Received: '${correctAnswer}'. The correct answer should be one of the options: [${options.join(', ')}]`);

  			}

			if (!expectedTypes.includes(type)) {
				errorMessages.push(`Question "${question}" (Row ${index + 1}): Incorrect 'type' value. Received: '${type}', Expected: [${expectedTypes.join(', ')}]`);
			}
		  
			if (!expectedComplexities.includes(complexity)) {
				errorMessages.push(`Question "${question}" (Row ${index + 1}): Incorrect 'complexity' value. Received: '${complexity}', Expected: [${expectedComplexities.join(', ')}]`);
			}

			if (expectedTypes.includes(type) && expectedComplexities.includes(complexity) && options.includes(correctAnswer)) {
				return {
					_id: index + 1,
					question: item["QUESTION"]?.trim(),
					// options: [item["A"], item["B"], item["C"], item["D"]]?.map(option => option?.trim()),
					// correctAnswer: item["Correct Answer"]?.trim(),
					options: [item["A"], item["B"], item["C"], item["D"]]?.map(option => option ? String(option).trim() : null),
					correctAnswer: item["Correct Answer"] ? String(item["Correct Answer"]).trim() : '',
					type: type,
					complexity: complexity,
					time: item["Time"],
					url: item["Image"],
				};
			} else {
				return null;
			}
		}).filter(item => item !== null);

		if (errorMessages.length > 0) {
			console.log(`Please review the following errors:\n\n${errorMessages.join('\n\n')}`);
			setQuestionsLoder(false)
			setErrorMessages(errorMessages)
			setQuestionErrorModalOpen(true)
			event.target.value = null
			return false;
		}

		// Remove duplicate questions
		let duplicateCount = 0;
		const combinedQuestions = [...totalQuestions, ...formattedData];
		const seen = new Set();
		const uniqueData = combinedQuestions.filter((el) => {
			const signature = JSON.stringify({
				question: el?.question,
				options: el?.options, // Sort to ensure the order of options does not affect uniqueness
				correctAnswer: el?.correctAnswer,
				type: el?.type,
				complexity: el?.complexity,
				time: el?.time,
				url: el?.url,
			});

			if (seen.has(signature)) {
				duplicateCount ++;
				return false;
			} else {
				seen.add(signature);
				return true;
			}
		});

		const totalUniqueRecords = formattedData?.length - duplicateCount;

		const message = `We've added ${totalUniqueRecords} new ${totalUniqueRecords === 1 ? 'record' : 'records'}. <br />` +
                `Note: ${duplicateCount} ${duplicateCount === 1 ? 'was' : 'were'} already in your list and ${duplicateCount === 1 ? 'was' : 'were'} not added again.`;

		setTotalQuestions((prevState) => {
			return [...uniqueData];
		});
		setData((prevState) => {
			return [...uniqueData];
		});
		setQuestionsLoder(false)
		event.target.value = null
		toast.success(<div dangerouslySetInnerHTML={{ __html: message }} />, {});
		  
	};

	const handleSingleQuestionData = async (data) => {
		const isUnique = isUniqueQuestion(data, totalQuestions);
		
		if(!isUnique) {
			toast.info('It looks like you are trying to add a question that already exists. Please check your question details and try again.');
			return false;
		}

		const obj = {
			id: totalQuestions?.length + 1,
			...data,
		};

		dispatch(
			quizState({
				key: "questions",
				value: [...totalQuestions, obj],
			})
		);

		setTotalQuestions([...totalQuestions, obj]);
		setData([...totalQuestions, obj]);
	};

	const handleEditQuestionData = async (id, data) => {
		// console.log(id, data);
		const updatedQuestions = totalQuestions.map((question) => {
			if (question._id === id || question.id === id) {
				return { ...question, ...data };
			}
			return question;
		});

		dispatch(
			quizState({
				key: "questions",
				value: updatedQuestions,
			})
		);

		setTotalQuestions(updatedQuestions);
		setData(updatedQuestions);
	};

	// useEffect(() => {
	// 	if (searchText) {
	// 		var searchResults = [];

	// 		// Search for the term in all fields
	// 		totalQuestions.forEach(function (question) {
	// 			var found = Object.values(question).some(function (value) {
	// 				if (typeof value === "string") {
	// 					return value
	// 						.toLowerCase()
	// 						.includes(searchText.toLowerCase());
	// 				}
	// 				return false;
	// 			});

	// 			if (found) {
	// 				searchResults.push(question);
	// 			}
	// 		});
	// 		setData(searchResults);
	// 	} else {
	// 		setData(
	// 			totalQuestions.slice(
	// 				pageData.pageIndex * pageData.pageSize,
	// 				pageData.pageSize * pageData.pageIndex + pageData.pageSize
	// 			)
	// 		);
	// 		setPageCount(Math.floor(totalQuestions.length / pageData.pageSize));
	// 	}
	// }, [totalQuestions, pageData, searchText]);

	const columns = React.useMemo(
		() => [
			{
				Header: "#",
				Cell: ({ row }) => {
					return row.index + 1;
				},
			},
			{
				Header: "Question",
				accessor: "question",
			},
			{
				Header: "Type",
				accessor: "type",
			},
			{
				Header: "Complexity",
				accessor: "complexity",
			},
			{
				Header: "Correct Answer",
				accessor: "correctAnswer",
			},
			{
				Header: "Action",
				Cell: ({ row }) => {
					return (
						<div className="d-flex justify-start">
							<FaUserEdit
								style={{
									fontSize: "20px",
									color: "#7385C9",
								}}
								onClick={() => {
									handleEdit(row?.original);
								}}
							/>
							<MdDelete
								style={{
									fontSize: "20px",
									marginLeft: "10px",
									color: " #BD5357",
								}}
								onClick={() => {
									handleDelete(row?.original?._id);
								}}
							/>
						</div>
					);
				},
			},
		],
		[]
	);

	const fetchData = useCallback(
		async ({ pageSize, pageIndex, sortBy }) => {
			setLoading(true);
			setPageData({
				pageSize: pageSize,
				pageIndex: pageIndex,
				sortBy: sortBy,
			});
			setLoading(false);
		},
		[debounceSearch]
	);

	const handleSearch = (searchText) => {
		const filter = data?.filter((q) => {
			return (
				q.question?.toLowerCase().includes(searchText.toLowerCase()) ||
				q.type?.toLowerCase().includes(searchText.toLowerCase()) ||
				q.correctAnswer
					?.toLowerCase()
					.includes(searchText.toLowerCase())
			);
		});
		setTotalQuestions(filter);
	};

	return (
		<div>
			<div className="d-flex justify-content-center fs-3 m-2">
				Questions
			</div>
			<div className="d-flex align-items-center justify-content-end m-3 flex-wrap">
				<div className="d-flex flex-wrap">
					<button
						className="btn btn-primary ms-3"
						onClick={() => {
							setShowQuizModel(true);
						}}
					>
						Add New Question <MdOutlineAddCircleOutline />
					</button>
					<input
						type="file"
						style={{ display: "none" }}
						ref={fileInputRef}
						accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						onChange={handleFileChange}
					/>
					<button
						className="btn btn-primary ms-3"
						onClick={handleButtonClick}
						disabled={isDataExtracting}
					>
						{isDataExtracting ? (
							"Please wait ..."
						) : (
							<Fragment>
								Import Questions <LuImport />
							</Fragment>
						)}
					</button>
					<Button
						href="/uploads/sample.xlsx"
						className="btn btn-successs sample-file-btn ms-3"
						download
					>
						<BsCloudDownloadFill className="pe-1" />
						Download Sample File
					</Button>
				</div>
			</div>
			<QuizQuestionModal
				show={showQuizModel}
				modalClose={() => {
					setShowQuizModel(false);
				}}
				handleSubmit={handleSingleQuestionData}
			/>
			<QuizQuestionModal
				show={showEditQuizModel}
				modalClose={() => {
					setShowEditQuizModel(false);
				}}
				data={editQuestionData}
				title="Edit Question"
				handleSubmit={handleEditQuestionData}
			/>
			<Container fluid>
				<div className="row">
					<div className="col-md-4">
						<label className="form-label pt-2">&nbsp;&nbsp;</label>
						<input
							type="text"
							placeholder="Search"
							className="form-control "
							aria-label="Search"
							onChange={(e) => {
								handleSearch(e.target.value);
								toggleRef.current = true;
							}}
						/>
					</div>
				</div>
				</Container>
				<Row>
					<section className=" py-0">
						<div className="">
							<div className="row gy-4 mt-2">
								<div className="col-lg-12">
									<div className="card mb-0">
										<div className="card-body pt-0">
											<CommonTable
												columns={columns}
												data={totalQuestions}
												fetchData={fetchData}
												loading={loading || questionLoader}
												pageCount={pageCount}
												manualPagination={false}
												manualSort={true}
												defaultSortColumn={"name"}
												isSelection={false}
												toggleRef={toggleRef}
												dataTotalCount={
													totalQuestions.length
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Row>
				<Row>
					<div className="d-flex align-items-center justify-content-end flex-wrap">
						<Button
							type="button"
							className="btn btn-secondary"
							onClick={() => {
								handlePrevious();
							}}
						>
							Previous
						</Button>
						<Button
							variant="primary"
							type="submit"
							disabled={isLoading || data.length == 0}
							className="ms-2"
							onClick={() => {
								handleSubmit();
							}}
						>
							{isLoading ? (
								<Fragment>
									<Spinner
										animation="border"
										size="sm"
										className="me-2"
									/>{" "}
									Please wait...
								</Fragment>
							) : (
								"Finish!"
							)}
						</Button>
					</div>
				</Row>
			<ErrorMessageForQuestion 
				show={questionErrorModalOpen}
				data={errorMessages}
				modalClose={() => {
					setQuestionErrorModalOpen(false)
				}}
			/>
		</div>
	);
};

export default QuizQuestion;
