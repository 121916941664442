import React, { Fragment, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import ModalCreatePersona from "../../components/persona/PersonaModel";
import CommonTable from "../../components/common/Table";
import { useDebounce } from "../../hooks/useDebounce";
import { useRef } from "react";
import { useCallback } from "react";
import AxiosRequest from "../../Utils/AxiosRequest";
import BreadCrumb from "../../components/common/BreadCrumb";
import { toast } from "react-toastify";

const Persona = () => {
	const [showPersonaModel, setShowPersonaModel] = useState(false);
	const [showEditPersonaModel, setShowEditPersonaModel] = useState(false);
	const [editContestData, setEditContestData] = useState({});

	const [searchText, setSearchText] = useState("");
	const [data, setData] = useState([]);
	const debounceSearch = useDebounce(searchText, 500);
	const fetchIdRef = useRef(0);
	const sortIdRef = React.useRef(0);
	const [loading, setLoading] = useState(false);
	const [pageCount, setPageCount] = useState(0);
	const [dataTotalCount, setDataTotalCount] = useState(0);
	const toggleRef = React.useRef(false);

	const [pageData, setPageData] = useState({
		pageSize: 10,
		pageIndex: 0,
		sortBy: [],
	});

	const handleEditContest = (data) => {
		try {
			setShowEditPersonaModel(true);
			setEditContestData(data.original);
		} catch (err) {
			console.log("err", err);
		}
	};

	const handleDeleteContest = (data) => {
		try {
			Swal.fire({
				icon: "error",
				title: "Are you sure?",
				text: "You want to delete this contest? Because user won't be able to see the quiz belongs to this contest.",
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: "#dc3545",
				confirmButtonText: "Yes",
				cancelButtonText: "No",
			}).then(async (result) => {
				if (result.isConfirmed) {
					const response = await AxiosRequest.delete(
						`/admin/persona/${data.original._id}`
					);
					fetchData(pageData);
					if (response.data?.status) {
						Swal.fire({
							icon: "success",
							title: "Contest has been deleted successfully!",
							confirmButtonColor: "#8d65db",
						});
					} else {
						Swal.fire({
							icon: "error",
							title: "Error in deleting contest. Please try again!",
							confirmButtonColor: "#8d65db",
						});
					}
				}
			});
		} catch (err) {
			toast.error(err.message);
		}
	};

	const fetchData = useCallback(
		async ({ pageSize, pageIndex, sortBy }) => {
			const sortId = ++sortIdRef.current;
			const fetchId = ++fetchIdRef.current;
			const sortColumn = sortBy[0]?.id;
			const sortOrder = sortBy[0]?.desc ? "desc" : "asc";
			setLoading(true);
			if (searchText) {
				pageIndex = 0;
			}
			setPageData({
				pageSize: pageSize,
				pageIndex: pageIndex,
				sortBy: sortBy,
			});

			if (fetchId === fetchIdRef.current) {
				const res = await AxiosRequest(
					`/admin/persona?limit=${pageSize}&page=${
						pageIndex + 1
					}&search=${searchText}&sortBy=${sortColumn}&sortAt=${sortOrder}`
				);
				setData(res?.data?.data?.data || []);
				setPageCount(Math.ceil(res?.data?.data.totalCount / pageSize));
				setDataTotalCount(res?.data?.data.totalCount)
				setLoading(false);
			}
		},
		[debounceSearch]
	);

	const columns = React.useMemo(
		() => [
			{
				Header: "#",
				Cell: ({ row }) => {
					return row.index + 1;
				},
			},
			{
				sorting: true,
				Header: "Name",
				accessor: "name",
                // width: 90,
				Cell: ({ row }) => {
					return row?.original?.name ? row?.original?.name : "-";
				},
			},
			{
				sorting: true,
				Header: "Description",
				accessor: "description",
                width: 500,
				Cell: ({ row }) => {
					return row?.original?.description
						?  row?.original?.description?.slice(0, 70) +
                        (row?.original?.description?.length > 70 ? " ..." : "")
						: "-";
				},
			},
			{
				sorting: true,
				Header: "Code",
				accessor: "uniqueCode",
				Cell: ({ row }) => {
					return row?.original?.uniqueCode
						? row?.original?.uniqueCode
						: "-";
				},
			},
			{
				sorting: true,
				Header: "Registered Users",
				accessor: "userCount",
				Cell: ({ row }) => {
					return row?.original?.userCount
						? row?.original?.userCount
						: "0";
				},
			},

			{
				sorting: true,
				Header: "Participated Users",
				accessor: "participantCount",
				Cell: ({ row }) => {
					return row?.original?.participantCount
						? row?.original?.participantCount
						: "0";
				},
			},

			{
				Header: "Action",
				Cell: ({ row }) => {
					return (
						<div className="d-flex justify-start">
							<FaUserEdit
								style={{
									fontSize: "20px",
									color: "#7385C9",
									cursor: "pointer",
								}}
								onClick={() => {
									handleEditContest(row);
								}}
							/>
							<MdDelete
								style={{
									fontSize: "20px",
									marginLeft: "10px",
									color: " #BD5357",
									cursor: "pointer",
								}}
								onClick={() => {
									handleDeleteContest(row);
								}}
							/>
						</div>
					);
				},
			},
		],
		[]
	);

	return (
		<div className="page-content form-page">
			<div className="bg-dash-dark-2 py-4">
				<div className="container-fluid d-flex align-items-center justify-content-between">
					<h3 className="mb-0">Contest</h3>
					<button
						className="btn btn-primary ms-3"
						onClick={() => {
							setShowPersonaModel(true);
						}}
					>
						Add Contest
					</button>
				</div>
			</div>

			<ModalCreatePersona
				modalClose={() => {
					setShowPersonaModel(false);
				}}
				show={showPersonaModel}
				pageData={pageData}
				fetchData={fetchData}
			/>
			<ModalCreatePersona
				modalClose={() => {
					setShowEditPersonaModel(false);
				}}
				show={showEditPersonaModel}
				data={editContestData}
				title="Edit Contest"
				pageData={pageData}
				fetchData={fetchData}
			/>

			<BreadCrumb page="Contest" />

			<section className="tables py-0">
				<div className="container-fluid">
					<div className="row flex item-center">
						<div className="col-md-4">
							<label className="form-label pt-2">
								&nbsp;&nbsp;
							</label>
							<input
								type="text"
								placeholder="Search"
								className="form-control "
								aria-label="Search"
								onChange={(e) => {
									setSearchText(e.target.value);
									toggleRef.current = true;
								}}
							/>
						</div>
					</div>
					<div className="row gy-4 mt-2">
						<div className="col-lg-12">
							<div className="card mb-0">
								<div className="card-header"></div>
								<div className="card-body pt-0">
									<div className="table-responsive">
										<CommonTable
											columns={columns}
											data={data}
											fetchData={fetchData}
											loading={loading}
											pageCount={pageCount}
											manualPagination={true}
											manualSort={true}
											// defaultSortColumn={"name"}
											isSelection={false}
											toggleRef={toggleRef}
											dataTotalCount={dataTotalCount}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Persona;
