import React, { useEffect } from "react";
import { Navigate, Outlet, useOutlet } from "react-router-dom/dist";

const AuthLayout = () => {
	const auth = localStorage.getItem("authToken");

	const {
		props: {
			children: {
				props: { children },
			},
		},
	} = useOutlet();

	useEffect(() => {
		document.title = children?.props?.title;
	}, [children?.props?.title]);

	return auth ? <Navigate to="/dashboard" /> : <Outlet />;
};
export default AuthLayout;
