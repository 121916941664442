import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../store/user/user.fetch";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
// import bizwiz from "../../assets/img/bizwiz.png";
import bizwiz from "../../assets/img/logo_rm_nxg.png";
import "../../assets/font/fontAptos.css"
const Login = () => {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, "Too Short!")
      .required("Password is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setIsLoading(true);
      dispatch(login(values, setIsLoading, navigate, setError ));
      setSubmitting(false);
    },
  });
  return (
    <div className="login-page">
      <div className="container d-flex align-items-center position-relative py-5">
        <div className="card shadow-sm w-100 rounded overflow-hidden bg-none">
          <div className="card-body p-0">
            <div className="row gx-0 align-items-stretch">
              <div className="col-lg-6">
                <div className="info d-flex justify-content-center flex-column p-4 h-100">
                  <div className="py-5 d-flex align-items-center">
                    <img src={bizwiz} alt="BrainPulses" 
                      style={{height: "100px"}}
                      />
                    {/* <div>
                    <h1 className="display-6 fw-bold ms-4 mt-4 fontAptos">BrainPulses</h1>
                    <h1 className=" fw-bold ms-4 fontAptos" style={{fontSize: "16px"}}>Powered by NextgenInnov8</h1>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 bg-white">
                <div className="d-flex align-items-center px-4 px-lg-5 h-100 bg-dash-dark-2">
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    className="w-75"
                  >
                    <div
                      className="input-material-group"
                      controlId="formBasicEmail"
                    >
                      <input
                        type="email"
                        placeholder="Enter email"
                        autoComplete="off"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        className="input-material"
                      />
                      {formik.errors.email && formik.touched.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                      ) : null}
                    </div>
                    <div
                      className="input-material-group"
                      controlId="formBasicPassword"
                    >
                      <input
                        autoComplete="new-password"
                        type="password"
                        placeholder="Password"
                        className="input-material"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.password && formik.touched.password ? (
                        <div className="text-danger">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary mb-3"
                        disabled={formik.isSubmitting || isLoading}
                      >
                        {formik.isSubmitting || isLoading ? (
                          <>
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-2"
                            />{" "}
                            Please wait...
                          </>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                    {error && <div className="text-danger">{error}</div>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
