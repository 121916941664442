import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./user/user.slice";
import quizSlice from "./quiz/quiz.slice";

export default configureStore({
  reducer: {
    user: userSlice,
    quiz: quizSlice,
  },
});
