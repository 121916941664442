import React, { useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Navigate, Outlet, useOutlet } from "react-router-dom/dist";
import { useSelector } from "react-redux";
import Footer from "../Footer";

const MainLayout = () => {
	const auth = localStorage.getItem("authToken");
	const {
		props: {
			children: {
				props: { children },
			},
		},
	} = useOutlet();

	useEffect(() => {
		document.title = children?.props?.title;
	}, [children?.props?.title]);

	return (
		<div>
			<Header />
			{auth ? (
				<div className="d-flex align-items-stretch">
					<Sidebar />
					<Outlet />
					<Footer />
				</div>
			) : (
				<Navigate to="/" />
			)}
		</div>
	);
};
export default MainLayout;
