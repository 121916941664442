import { useEffect, useState } from "react";
import QuizDetail from "../../components/quiz/QuizDetail";
import QuizQuestion from "../../components/quiz/QuizQuestion";
import "../../pages/quiz/stapper.css";
import Stepper from "react-stepper-horizontal";
import QuizRoundNew from "../../components/quiz/QuizRound_New";
import { Link, useParams } from "react-router-dom";
import AxiosRequest from "../../Utils/AxiosRequest";
import { useDispatch } from "react-redux";
import { quizState } from "../../store/quiz/quiz.slice";
import { FaUser } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import checked from "../../assets/img/checked.svg"

const QuizCreate = ({ mode }) => {

	const [quiz, setQuiz] = useState(null);
	const params = useParams();
	const dispatch = useDispatch();

	const fetchQuizData = async () => {
		const res = await AxiosRequest.get(`/admin/quiz/${params.id}`);
		setQuiz(res?.data?.data);

		dispatch(
			quizState([
				{ key: "quizDetail", value: res?.data?.data },
				{ key: "rounds", value: res?.data?.data?.rounds },
				{ key: "questions", value: res?.data?.data?.questions },
			])
		);
	};

	useEffect(() => {
		if (mode === "edit") {
			fetchQuizData();
		}
	}, [params.id, mode]);

	const handleNext = () => {
		if (activeStep < isStepDisabled.length - 1) {
			setActiveStep(activeStep + 1);
			setIsStepDisabled((prev) => {
				const updated = [...prev];
				updated[activeStep + 1] = false; // Enable the next step
				return updated;
			});
		}
	};
	const handlePrevious = () => {
		if (activeStep !== 0) {
			setActiveStep(activeStep - 1);
			setIsStepDisabled((prev) => {
				const updated = [...prev];
				updated[activeStep - 1] = false;
				return updated;
			});
		}
	};

	function renderStepContent(step) {
		switch (step) {
			case 0:
				return <QuizDetail handleNext={handleNext} mode={mode} />;
			case 1:
				return (
					<QuizRoundNew
						handleNext={handleNext}
						handlePrevious={handlePrevious}
						mode={mode}
					/>
				);
			case 2:
				return (
					<QuizQuestion
						handleNext={handleNext}
						handlePrevious={handlePrevious}
						mode={mode}
					/>
				);
			default:
				return <div>Not Found</div>;
		}
	}

	const [activeStep, setActiveStep] = useState(0);
	const [isStepDisabled, setIsStepDisabled] = useState([false, false, false]);

	const handleStepClick = (index) => {
		if (!isStepDisabled[index]) {
			setActiveStep(index);
		}
	};

	return (
		<div className="page-content form-page">
			<div className="bg-dash-dark-2 py-4">
				<div className="container-fluid d-flex align-items-center justify-content-between">
					<h3 className="mb-0">{mode === "create" ? "Add" : "Edit"} Quiz</h3>
				</div>
			</div>

			<div className="container-fluid py-2">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb mb-0 py-3 px-0">
						<li className="breadcrumb-item">
							<Link to="/dashboard">Home</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							<Link to="/quiz">Quiz</Link>
						</li>
						<li className="breadcrumb-item active" aria-current="page">
							{mode === "create" ? "Add Quiz" : `Edit Quiz - ${quiz?.title}` }
						</li>
					</ol>
				</nav>
			</div>

			<section className="tables py-0">
				<div className="container-fluid">
					<div className="row gy-4 mt-2">
						<div className="col-lg-12">
							<div className="card mb-0">
								<div className="card-header custom-stepper">
									<Stepper
										steps={[
											{ title: "Quiz Detail", icon: activeStep > 0 ? checked : null },
											{ title: "Quiz Round", icon: activeStep > 1 ? checked : null},
											{ title: "Quiz Question", icon: activeStep > 2 ? checked : null },
										]}
										activeStep={activeStep}
										onClick={handleStepClick}
										disabledSteps={isStepDisabled}
										defaultColor="#c7c5c5"
										activeTitleColor="#fff"
										activeColor="#8d65db"
										completeTitleColor="#eee"
										completeColor="#28a745"
										defaultTitleColor="#bbb"
										circleFontColor="#fff"
									/>
								</div>
								<div className="card-body pt-0">
									{renderStepContent(activeStep)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default QuizCreate;
