import React, { useEffect, useRef } from "react";
import { Button, Table } from "react-bootstrap";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, onChange, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<>
				<input
					className="form-check-input"
					type="checkbox"
					ref={resolvedRef}
					onChange={(e) => {
						onChange(e);
					}}
					{...rest}
				/>
			</>
		);
	}
);

const useOnceCall = (cb, condition = true, toggle = true) => {
	const isCalledRef = useRef(false);

	useEffect(() => {
		if (condition && !isCalledRef.current) {
			isCalledRef.current = true;
			cb();
		}
	}, [cb, condition, toggle]);
};

const CommonTable = ({
	columns,
	data,
	fetchData,
	loading,
	pageCount: controlledPageCount,
	manualPagination,
	manualSort = false,
	defaultSortColumn = "",
	setSelectedRows = null,
	isSelection,
	toggleRef,
	dataTotalCount
}) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		selectedFlatRows,
		state: { pageIndex, pageSize, sortBy },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: 10,
				sortBy: defaultSortColumn
					? [
							{
								id: defaultSortColumn,
								desc: false,
							},
					  ]
					: [],
			},
			manualPagination: manualPagination,
			manualSortBy: manualSort,
			pageCount: manualPagination ? controlledPageCount : data.length,
		},
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			if (isSelection) {
				hooks.visibleColumns.push((columns) => {
					return [
						{
							id: "selection",
							Header: ({ getToggleAllRowsSelectedProps }) => {
								return (
									<div>
										<IndeterminateCheckbox
											{...getToggleAllRowsSelectedProps()}
										/>
									</div>
								);
							},
							Cell: ({ row }) => {
								return (
									<div>
										<IndeterminateCheckbox
											// disabled={row?.original?.deletedAt}
											{...row.getToggleRowSelectedProps()}
										/>
									</div>
								);
							},
						},
						...columns,
					];
				});
			}
		}
	);

	useEffect(() => {
		if (manualPagination && toggleRef.current) {
			fetchData({ pageIndex, pageSize, sortBy });
			toggleRef.current = false;
		}
	}, [fetchData, pageIndex, pageSize, sortBy]);

	useOnceCall(() => {
		if (manualPagination) {
			//   if (itemsPerPage) {
			// 	setPageSize(Number(itemsPerPage));
			//   }
			//   const pageNo = !paginationIndex ? 0 : pageIndex * pageSize;
			//   const sortDirection = sortColumnDirection ? "asc" : "desc";
			//   const _pageSize = itemsPerPage ? itemsPerPage : pageSize;
			fetchData({ pageIndex, pageSize, sortBy });
		}
	}, [pageIndex, pageSize, sortBy]);

	useEffect(() => {
		if (setSelectedRows) {
			setSelectedRows(selectedFlatRows);
		}
	}, [selectedFlatRows]);

	return (
		<>
		<div className="tableFixHead">
			<Table {...getTableProps()} hover responsive>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps(
										column.getSortByToggleProps()
									)}
									onClick={(e) => {
										if (column?.sorting) {
											column
												.getHeaderProps(
													column.getSortByToggleProps()
												)
												?.onClick(e);
											toggleRef.current = true;
										}
									}}
									style={{ width: column?.width !== 150 ? column?.width : undefined }}
								>
									<div className="d-flex justify-content-between">
										{column.render("Header")}
										<span style={{ display: "flex", flexDirection: "column" }}>
											{column?.sorting ? (
												column.isSorted ? (
													column.isSortedDesc ? (
														<>
															<FaSortUp style={{ color: 'gray' }} />
															<FaSortDown style={{ marginTop: "-12px" }} />
														</>
														//Descending Symbol
													) : (
														<>
															<FaSortUp />
															<FaSortDown style={{ color: 'gray', marginTop: "-12px" }} />
														</>
														// Ascending Symbol
													)
												) : <>
													<FaSortUp style={{ color: 'gray' }} />
													<FaSortDown style={{ color: 'gray', marginTop: "-12px" }} />
												</>
											) : null}
										</span>
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				{loading ? (
					<tbody>
						<tr>
							<td colSpan="100%">
								<div className="d-flex justify-content-center align-items-center">
									<div className="page_loader" />
								</div>
							</td>
						</tr>
					</tbody>
				) : data.length ? (
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()} key={i + "row"}>
									{row.cells.map((cell, i) => {
										return (
											<td
												{...cell.getCellProps()}
												key={"cell" + i}
											>
												{cell.render("Cell")?.props
													?.value !== "null"
													? cell.render("Cell")
													: "n/a"}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				) : (
					<tbody>
						<tr>
							<td colSpan="100%">
								<div className="d-flex justify-content-center">
									No Data Found
								</div>
							</td>
						</tr>
					</tbody>
				)}
			</Table>
			</div>
			{data?.length && dataTotalCount > 10 ? (
				<div className="text-center d-flex justify-content-between mt-4 mb-1">
					<div className="d-flex">
						<Button
							variant="dark"
							className="mx-2 text-white"
							onClick={() => {
								gotoPage(0);
								toggleRef.current = true;
							}}
							disabled={!canPreviousPage}
						>
							{"<<"}
						</Button>
						<Button
							variant="dark"
							className="mx-2 bg-[#db6574] text-white"
							onClick={() => {
								previousPage();
								toggleRef.current = true;
							}}
							disabled={!canPreviousPage}
						>
							{"<"}
						</Button>
						<Button
							className="mx-2 bg-[#db6574] text-white"
							onClick={() => {
								nextPage();
								toggleRef.current = true;
							}}
							disabled={!canNextPage}
							style={{ backgroundColor: "#db6574" }}
						>
							{">"}
						</Button>
						<Button
							onClick={() => {
								gotoPage(pageCount - 1);
								toggleRef.current = true;
							}}
							disabled={!canNextPage}
							className="mx-2 text-white"
							style={{ backgroundColor: "#db6574" }}
						>
							{">>"}
						</Button>
						<span className="mx-2 d-flex align-items-center">
							Page
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>
						</span>
						<div className="d-flex align-items-center">
							<span>| Go to page:</span>
							<input
								type="number"
								max={pageOptions.length}
								min={1}
								defaultValue={pageIndex + 1}
								onChange={(e) => {
									if (
										e.target.value <= pageOptions.length &&
										e.target.value >= 1
									) {
										const page = e.target.value
											? Number(e.target.value) - 1
											: 0;
										gotoPage(page);
									} else {
										gotoPage(1);
									}
									toggleRef.current = true;
								}}
								className="form-control ms-2"
								style={{ width: "50px" }}
							/>
						</div>
					</div>
					<div>
						<select
							value={pageSize}
							onChange={(e) => {
								setPageSize(Number(e.target.value));
								toggleRef.current = true;
							}}
							className="form-select"
						>
							{[10, 20, 30, 40, 50].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</select>
					</div>
				</div>
			) : null}
		</>
	);
};
export default CommonTable;
