import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom/dist";
import { FaList, FaUserAlt, FaClipboardList, FaUsers, FaPager } from "react-icons/fa";
import defaultUser from "../assets/img/default-user.png";
import AxiosRequest from "../Utils/AxiosRequest";

const Sidebar = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	const [isShow, setIsShow] = useState(false);
	const [reactivationCount ,setReactivationCount] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();



	const fetchData = async () => {	
		const res = await AxiosRequest(`/admin/user/revoke-request-list`);
		setReactivationCount(res?.data?.data.totalCount);
	}

useEffect(() => {
	fetchData();
}, [])

	return (
		<nav id="sidebar">
			<div className="sidebar-header d-flex align-items-center p-4">
				<img
					className="avatar shadow-0 img-fluid rounded-circle"
					src={defaultUser}
					alt={user?.name}
				/>
				<div className="ms-3 title">
					<h1 className="h5 mb-1">{user?.name}</h1>
				</div>
			</div>
			<ul className="list-unstyled">
				<li className={location.pathname === "/dashboard" ? "sidebar-item active" : "sidebar-item"}
					onClick={() => {
						navigate("/dashboard");
					}}
				>
					<NavLink className="sidebar-link" to="/dashboard">
						<svg className="svg-icon svg-icon-sm svg-icon-heavy">
							<use xlinkHref="#real-estate-1"> </use>
						</svg>
						<span>Dashboard </span>
					</NavLink>
				</li>
				<li  
					class={location.pathname === "/users" ? "sidebar-item active" : "sidebar-item"}>
					{reactivationCount > 0 ? <>
						<a 
						class="sidebar-link"
						href="#requestUser" data-bs-toggle="collapse" aria-expanded="false" 
						onClick={() => 
							{setIsShow(!isShow)
							navigate("/users")}}
						> 
						<FaUserAlt className="svg-icon svg-icon-sm svg-icon-heavy" />
						<span>Users </span>
					</a>
					<ul className={`collapse list-unstyled ${isShow ? "show" : ""}`} id="requestUser">
						{/* <li><a class="sidebar-link" href="#">Page</a></li> */}
						<li className={location.pathname === "/reactivation-request" ? "sidebar-item active" : "sidebar-item"}>
							<NavLink className="sidebar-link" to="/reactivation-request">
								<span>Reactivation Request </span>
							</NavLink>
						</li>
					</ul>
					</> : <NavLink className="sidebar-link" to="/users">
					<FaUserAlt className="svg-icon svg-icon-sm svg-icon-heavy" />
						<span>Users </span>
					</NavLink>}
					
				</li>
				{/* <li className="sidebar-item">
					<div className={location.pathname === "/users" ? "active" : ""}>
						<a className="sidebar-link" href="#requestUser" data-bs-toggle="collapse" onClick={() => navigate("/users")}> 
							<FaUserAlt className="svg-icon svg-icon-sm svg-icon-heavy" />
						<span>Users </span>
						</a>
					</div>
                	<ul className="collapse list-unstyled " id="requestUser">
						<li className={location.pathname === "/reactivation-request" ? "sidebar-item active" : "sidebar-item"}>
							<NavLink className="sidebar-link" to="/reactivation-request">
								<span>Reactivation Request </span>
							</NavLink>
						</li>
                	</ul>
             	</li> */}
				<li className={location.pathname === "/quiz" ? "sidebar-item active" : "sidebar-item"}>
					<NavLink className="sidebar-link" to="/quiz">
						<FaList className="svg-icon svg-icon-sm svg-icon-heavy" />
						<span>Quiz </span>
					</NavLink>
				</li>
				<li className={location.pathname === "/reports" ? "sidebar-item active" : "sidebar-item"}>
					<NavLink className="sidebar-link" to="/reports">
						<FaClipboardList className="svg-icon svg-icon-sm svg-icon-heavy" />
						<span>Reports </span>
					</NavLink>
				</li>
				<li className={location.pathname === "/sponsers" ? "sidebar-item active" : "sidebar-item"}>
					<NavLink className="sidebar-link" to="/sponsers">
						<FaPager className="svg-icon svg-icon-sm svg-icon-heavy" />
						<span>Sponsers </span>
					</NavLink>
				</li>
				<li className={location.pathname === "/contest" ? "sidebar-item active" : "sidebar-item"}>
					<NavLink className="sidebar-link" to="/contest">
						<FaUsers className="svg-icon svg-icon-sm svg-icon-heavy" />
						<span>Contest </span>
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default Sidebar;
