import React, { Fragment, useEffect } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { uploadImageToAzure } from "../../Utils/ImageUpload";
import * as Yup from "yup";
import { Button, Col, Container, Form, Image, Modal, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { FaTimesCircle } from "react-icons/fa";
import { createQuizDetail, personaList, sponserList } from "../../store/quiz/quiz.fetch";
import moment from "moment";
import AxiosRequest from "../../Utils/AxiosRequest";
import { useDispatch } from "react-redux";
import { quizState } from "../../store/quiz/quiz.slice";

const FinishedQuiz = ({ show, modalClose, data = "", pageData, fetchData }) => {

    const [personas, setPersonas] = useState([]);
    const [sponsers, setSponsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
	const [imageError, setImageError] = useState("");
    const [initialValues, setInitialValues] = useState({})

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
		start_date: Yup.date(),
		end_date: Yup.date().min(Yup.ref("start_date"),"End date must be greater than or equal to start date")
	});

    useEffect(() => {
        personaList().then((response) => {
			setPersonas(
				response.map((item) => {
					return {
						id: item._id,
						label: item.name,
						value: item._id,
					};
				})
			);
		});
        sponserList().then((response) => {
			setSponsers(
				response.map((item) => {
					return {
						key: item.name,
						value: item._id,
					};
				})
			);
		});
    }, [])

    useEffect(() => {
	
        const _sponser = typeof data?.sponsor === 'string' ?  sponsers?.find(({value}) => value === data?.sponsor) : {
            key: data?.sponsor?.name,
            value: data?.sponsor?._id,
        }
        
        setInitialValues({
            id: data?._id,
            title: data?.title,
            rounds: data?.totalRounds,
            start_date: moment(data?.startDate).format("YYYY-MM-DD"),
            end_date: data?.endDate ?  moment(data?.endDate).format("YYYY-MM-DD") : "",
            practiceQuiz: data?.practiceQuiz,
            selectedPersona: data?.persona?.map((item) => {
                const find = typeof item === 'string' ? personas?.find(({id}) => id === item) : personas?.find(({id}) => id === item?._id)
                return { id: find?.id, label: find?.label, value: find?.id }
            }),
            sponser: _sponser,
            image: data?.image,
        })

	}, [data, sponsers.length, personas.length]);

    const customStyles = {
		option: (defaultStyles, state) => ({
			...defaultStyles,
			color: state.isSelected ? "#343a40" : "#fff",
			backgroundColor: state.isSelected ? "#a0a0a0" : "#343a40",
		}),

		control: (defaultStyles, state) => ({
			...defaultStyles,
			backgroundColor: "#343a40",
			border: "none",
			boxShadow: "none",
			borderColor: state.isFocused ? "#8d65db" : "gray",
			"&:hover": {
				border: "gray",
			},
		}),
		singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: "white",
			};
		},
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.color,
			backgroundColor: "#FFBDAD",
			color: "red",
		}),
	};

    const handleImageUpload = async (e) => {
		setIsLoading(true);
		setImageError("");
		const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];

		if(supportedFormats.includes(e.target.files[0].type)) { 
			const url = await uploadImageToAzure(e.target.files[0]);
			formik.setFieldValue("image", url);
			setIsLoading(false);
		}else {
			formik.setFieldValue("image", "");
			setImageError("Only image files are allowed");
			setIsLoading(false);
		}

	};

    const formik = useFormik({
		initialValues: initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (value, { setSubmitting }) => {
			setSubmitting(true);
			let startDate = moment(value.start_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
			let endDate = value.end_date;

			if(endDate) {
				endDate = moment(value.end_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
			}

			if(!value.sponser) delete value.sponser
			if(typeof value.sponser === 'object') value.sponser = value.sponser.value
			const apiRequest = {
				id: value.id,
				title: value.title,
				practiceQuiz: value.practiceQuiz,
				startDate: startDate,
				endDate: endDate,
				totalRounds: value.rounds,
				persona: value.selectedPersona?.map((item) => item.value),
				sponsor: value?.sponser,
				image: value.image,
			};

            try {
                const { data } = await AxiosRequest.post("admin/quiz", apiRequest);
                dispatch(
                  quizState({
                    key: "quizDetail",
                    value: data.data,
                  })
                );
                setSubmitting(false);
                modalClose();
                formik.resetForm();
                fetchData(pageData);
                toast.success("Quiz detail updated successfully!")
                setImageError("")
              } catch (err) {
                toast.error("Error in updating quiz. Please try again!");
                setSubmitting(true);
              }
			
		},
	});

	return (
		<Modal size="xl" show={show} onHide={modalClose}>
			<Modal.Header closeButton>
				<Modal.Title>Quiz</Modal.Title>
			</Modal.Header>
			<Form onSubmit={formik.handleSubmit}>
				<div
					className="d-flex justify-content-center"
					style={{ overflowY: "auto", height: "500px" }}
				>
					<Modal.Body>
						<Container fluid>
                        <Row>
							<Col className="col-6">
								<Form.Group
								className="form-group mt-3"
								controlId="formBasicName"
								>
									<Form.Label>Title</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter title"
										autoComplete="off"
										name="title"
										readOnly={true}
										value={formik.values.title}
										onChange={formik.handleChange}
										className="form-control mt-1"
									/>
									{formik.errors.title && formik.touched.title ? (
										<Form.Text className="text-danger">
											{formik.errors.title}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
							<Col className="col-6 ">
								<Form.Group
									controlId="formLogoImage"
									className="form-group"
									style={{ marginTop: "48px" }}
								>
									<Form.Check
										type="checkbox"
										name="practiceQuiz"
										label="Practice Quiz ?"
										className="custom-date-input"
										disabled={true}
										checked={formik.values.practiceQuiz}
										value={formik.values.practiceQuiz}
										onChange={formik.handleChange}
									/> 
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="col-6">
								<Form.Group
									className="form-group mt-3 m-auto"
									controlId="formBasicPersona"
								>
									<Form.Label className="pt-2">
										Contest's
									</Form.Label>
									<Select
										defaultValue={formik.values.selectedPersona}
										options={personas}
										isMulti
										readOnly={true}
										isDisabled={true}
										value={formik.values.selectedPersona ? formik.values.selectedPersona : []}
										styles={customStyles}
									/>
								</Form.Group>
							</Col>
							<Col className="col-6">
								<Form.Group
									className="form-group mt-3"
									controlId="formBasicStartDate"
								>
									<Form.Label className="pt-2">
										Sponser
									</Form.Label>
									<Form.Select
										className=""
										name="sponser"
										readOnly={true}
                                        disabled={true}
                                        style={{ overflowY: "scroll" }}
										value={formik.values?.sponser?.value}
									>
										<option value="">Please select</option>
										{sponsers.map((item, index) => (
											<option
												value={item.value}
												key={index}
											>
												{item.key}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="col-6">
								<Form.Group
									className="form-group mt-3"
									controlId="formBasicRound"
								>
									<Form.Label>Total Round</Form.Label>
									<Form.Control
										type="number"
										min="1"
										placeholder="Enter total round"
										autoComplete="off"
										name="rounds"
										readOnly={true}
										value={formik.values.rounds}
										onChange={formik.handleChange}
										className="form-control mt-1"
									/>
									{formik.errors.rounds &&
									formik.touched.rounds ? (
										<Form.Text className="text-danger">
											{formik.errors.rounds}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
							<Col className="col-6">
								<Form.Group
									controlId="formLogoImage"
									className="form-group mt-3"
								>
									<Form.Label>Image</Form.Label>
									<Form.Control
										type="file"
										accept="image/*"
										onChange={(e) => {
											handleImageUpload(e);
										}}
									/>
									{imageError && (
										<Form.Text className="text-danger validation-message">
											{imageError}
										</Form.Text>
									)}

									{formik.values.image && (
										<div
											className="position-relative mt-3"
											style={{
												width: "150px",
												height: "auto",
											}}
										>
											<Image
												src={formik.values.image}
												fluid
												alt="Preview"
												sizes="sm"
											/>
											<FaTimesCircle
												className="position-absolute top-0 end-0 mt-2 me-2 cursor-pointer"
												// style={{
												// 	top: "-7px",
												// 	right: "9px"
												// }}
												onClick={() => {
													formik.setFieldValue("image","");
												}}
											/>
										</div>
									)}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="col-6">
								<Form.Group
									className="form-group mt-3"
									controlId="formBasicStartDate"
								>
									<Form.Label>Start Date</Form.Label>
									<Form.Control
										type="date"
										name="start_date"
										placeholder="start Date"
										readOnly={true}
										className="custom-date-input"
										value={formik.values.start_date}
										onChange={formik.handleChange}
									/>
									{formik.errors.start_date &&
									formik.touched.start_date ? (
										<Form.Text className="text-danger">
											{formik.errors.start_date}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
							<Col className="col-6">
								<Form.Group
									className="form-group mt-3"
									controlId="formBasicStartDate"
								>
									<Form.Label>End Date</Form.Label>
									<Form.Control
										type="date"
										name="end_date"
										placeholder="end Date"
										className="custom-date-input"
										value={formik.values.end_date}
										min={new Date().toISOString().split('T')[0]}
                                        // min={formik.values.start_date ? new Date(formik.values.start_date).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]}
										onChange={formik.handleChange}
									/>
									{formik.errors.end_date &&
									formik.touched.end_date ? (
										<Form.Text className="text-danger">
											{formik.errors.end_date}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
						</Row>
						</Container>
					</Modal.Body>
				</div>
				<Modal.Footer className="mt-3">
					<Button variant="secondary" onClick={() => {
							modalClose();
							formik.resetForm();
							setImageError("")
						}}>
						Close
					</Button>
                    <Button
						variant="primary"
						type="submit"
						disabled={formik.isSubmitting || isLoading}
					>
						{formik.isSubmitting || isLoading ? (
							<Fragment>
								<Spinner
									animation="border"
									size="sm"
									className="me-2"
								/>{" "}
								Please wait...
							</Fragment>
						) : (
							"Save"
						)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default FinishedQuiz;
