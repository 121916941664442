import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { Button, Form, Image, Modal, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import AxiosRequest from "../../Utils/AxiosRequest";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { FaTimesCircle } from "react-icons/fa";
import { uploadImageToAzure } from "../../Utils/ImageUpload";

const ModalCreateSponser = ({
	show,
	modalClose,
	data = "",
	title = "Create Sponser",
}) => {
	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.min(5, "Please enter min 5 characters long name!")
			.required("Sponser name is required"),
		type: Yup.string().required("Selection of type is required"),
		imageUrl: Yup.string().required("Selection of image is required"),
	});

	const [initialValues, setInitialValues] = useState({
		name: "",
		type: "",
		imageUrl: "",
	});
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [imageError, setImageError] = useState("");

	useEffect(() => {
		if (data) {
			setInitialValues({
				name: data.name,
				type: data.type,
				imageUrl: data.imageUrl,
			});
			console.log("data", data);
		}
	}, [data]);

	const handleImageUpload = async (e) => {
		setIsLoading(true);

		setImageError("");
		const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];

		if(supportedFormats.includes(e.target.files[0].type)) {  
			const url = await uploadImageToAzure(e.target.files[0]);
			formik.setFieldValue("imageUrl", url);
			setIsLoading(false);
		}else {
			formik.setFieldValue("imageUrl", "");
			setImageError("Only image files are allowed");
			setIsLoading(false);
		}

	};
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (value, { setSubmitting }) => {
			setSubmitting(true);
			if (data) {
				const response = await AxiosRequest.patch(
					`/admin/sponser/${data._id}`,
					{
						...value,
					}
				).catch((err) => {
					console.log("err", err);
					toast.error(err.response?.data?.message);
				});
				toast.success(response.data.message);
				formik.resetForm();
				setImageError("")
			} else {
				const response = await AxiosRequest.post("/admin/sponser", {
					...value,
				}).catch((err) => {
					console.log("err", err);
					toast.error(err.response?.data?.message);
				});
				formik.resetForm();
				setImageError("")
				toast.success(response.data.message);
			}
			setSubmitting(false);
			modalClose();
			setInitialValues(initialValues);
			navigate("/sponsers");
		},
	});
	return (
		<Modal size="lg" show={show} onHide={modalClose}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={formik.handleSubmit}>
				<div className="d-flex justify-content-center" style={{ overflowY: 'auto', height: '500px' }}>
					<Modal.Body>
						<Form.Group
							className="form-group mt-3"
							controlId="formBasicName"
						>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter name"
								autoComplete="off"
								name="name"
								value={formik.values.name}
								onChange={formik.handleChange}
								className="form-control mt-1"
							/>
							{formik.errors.name && formik.touched.name ? (
								<Form.Text className="text-danger">
									{formik.errors.name}
								</Form.Text>
							) : null}
						</Form.Group>
						<Form.Group
							className="form-group mt-3"
							controlId="formBasicType"
						>
							<Form.Label>Type</Form.Label>
							<Form.Select
								className="w-50"
								name="type"
								value={formik.values.type}
								onChange={formik.handleChange}
							>
								<option value="">Please Select</option>
								<option value="contest">Contest</option>
								<option value="app">App</option>
							</Form.Select>
							{formik.errors.type && formik.touched.type ? (
								<Form.Text className="text-danger">
									{formik.errors.type}
								</Form.Text>
							) : null}
						</Form.Group>
						<Form.Group
							controlId="formLogoImage"
							className="form-group mt-3"
						>
							<Form.Label>Photo</Form.Label>
							<Form.Control
								type="file"
								accept="image/*"
								onChange={(e) => {
									handleImageUpload(e);
								}}
							/>
							{imageError && (
								<Form.Text className="text-danger validation-message">
									{imageError}
								</Form.Text>
							)}
							{formik.errors.imageUrl  ? (
								<Form.Text className="text-danger">
									{formik.errors.imageUrl}
								</Form.Text>
							) : null}

							{formik.values.imageUrl && (
								<div
									className="position-relative mt-3"
									style={{ width: "100px", height: "auto" }}
								>
									<Image
										src={formik.values.imageUrl}
										fluid
										alt="Preview"
										rounded
									/>
									<FaTimesCircle
										className="position-absolute top-0 end-0 mt-2 me-2 cursor-pointer"
										// style={{
										// 	top: "-7px",
										// 	right: "59px"
										// }}
										onClick={() => {
											formik.setFieldValue(
												"imageUrl",
												""
											);
										}}
									/>
								</div>
							)}
						</Form.Group>
					</Modal.Body>
				</div>
				<Modal.Footer className="mt-3">
					<Button variant="secondary" onClick={() => {
							modalClose();
							formik.resetForm();
							setImageError("")
						}}>
						Close
					</Button>
					<Button
						variant="primary"
						type="submit"
						disabled={formik.isSubmitting || isLoading}
					>
						{formik.isSubmitting || isLoading ? (
							<Fragment>
								<Spinner
									animation="border"
									size="sm"
									className="me-2"
								/>{" "}
								Please wait...
							</Fragment>
						) : (
							"Save"
						)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ModalCreateSponser;
