import React from "react";
import { Link, useLocation } from "react-router-dom";

const BreadCrumb = ({page}) => {

	const location = useLocation();
	const paths = location.pathname.split('/').filter(path => path !== '');
	
	return (
		<div className="container-fluid py-2">
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 py-3 px-0">
					{ paths[paths.length - 1] !== 'dashboard' ? <li  className="breadcrumb-item">
							<Link to="/dashboard">Dashboard</Link>
						</li> : null
					}
					{
						paths?.map((path, index) => {
							if(paths.length - 1 === index) {
								return <li key={index} className="breadcrumb-item">
									{page}
								</li>
							}
							return <li key={index} className="breadcrumb-item">
								<Link to={`/${paths.slice(0, index + 1).join('/')}`}>{path.charAt(0).toUpperCase() + path.slice(1)}</Link>
							</li>
						})
					}
				</ol>
			</nav>
		</div>
	);
};

export default BreadCrumb;
