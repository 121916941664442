import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { BiExport } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import BreadCrumb from "../../components/common/BreadCrumb";
import AxiosRequest from "../../Utils/AxiosRequest";
import moment from "moment";

const Reports = () => {
	const [type, setType] = useState("participant");
	const [quiz, setQuiz] = useState("");
	const [round, setRound] = useState("");
	const [contest, setContest] = useState("");
	const [error, setError] = useState("");
	const [quizList, setQuizList] = useState([]);
	const [quizRound, setQuizRound] = useState([]);
	const [contestList, setContestList] = useState([]);
	const [tableData, setTableData] = useState({});
	const [filter, setFilters] = useState({
		limit: 10,
		page: 1,
		search: "",
	});
	const [isLoading, setLoading] = useState(false);
	const changeFilter = (name, value) => {
		setFilters({ ...filter, [name]: value });
		const apiPayload = { ...filter, [name]: value };
	};

	useEffect(() => {
		_handleGenerate(filter.page, filter.limit);
	}, [filter.page]);

	const getApiData = async (page, limit, data) => {
		const res = await AxiosRequest.post(`/admin/report?limit=${limit}&page=${page}`, data);
		return res.data;
	};

	const handleType = async (value) => {
		console.log("value", value);
		if(value !== "participant") {
			setTableData({})
		}
		setError("");
		setType(value);
		setQuiz("");
		setLoading(true);
		if (value === "quiz" || value === "quiz_round") {
			const quizDataList = await AxiosRequest.post(
				`admin/dropdown-list`,
				{ type: "quiz" }
			);
			setQuizList(quizDataList.data?.data);

			const tempType = value === "quiz" ? "quiz" : "quiz_round";

			console.log("tempType = ", tempType);
			if(quizDataList.data?.data.length > 0) {
				console.log("quizDataList.data?.data[0] = ", quizDataList.data?.data[0]);
				handleQuiz(quizDataList.data?.data[0]._id, filter.page, filter.limit, tempType)
			}
			setLoading(false);
		}
		if (value === "persona") {
			const personaDataList = await AxiosRequest.post(
				`admin/dropdown-list`,
				{ type: "persona" }
			);
			if(personaDataList?.data?.data?.length > 0) {
				handleContest(personaDataList?.data?.data[0]._id, filter.page, filter.limit)
			}
			setContestList(personaDataList.data?.data);
			setLoading(false);
		}
	};

	const handleQuiz = async (value, page, limit, customType) => {
		console.log("value, page, limit = ", value, page, limit, customType);
		setError("");
		setQuiz(value);
		setLoading(true);
		if (customType === "quiz_round") {
			setQuizRound([])
			// setTableData({})
			const roundDataList = await AxiosRequest.post(
				`admin/dropdown-list`,
				{
					type: "round",
					quizId: value,
				}
			);
			setQuizRound(roundDataList.data?.data);
			if(roundDataList.data?.data.length > 0) {
				console.log("roundDataList.data?.data[0] = ", roundDataList.data?.data[0]);
				handleRound(roundDataList.data?.data[0]._id, filter.page, filter.limit)
			}
			setLoading(false);
		}
		if (customType === "quiz") {
			const data = await getApiData(page, limit, {
				type: "quiz",
				quizId: value,
			});
			const apiDataList = data.data.data.map((item, index) => {
				return {
					index: index + 1,
					name: item.user?.name ? item.user?.name : "-",
					email: item.user?.email ? item.user?.email : "-",
					phone_number: item.user?.mobileNumber
						? item.user?.mobileNumber
						: "-",
					attempted_round: item?.attemptedRounds
						? item?.attemptedRounds
						: "-",
				};
			});
			setTableData({
				from: (filter.page - 1) * filter.limit,
				to:
					filter.page * filter.limit > data?.data?.totalCount
						? data?.data?.totalCount
						: filter.page * filter.limit,
				total: data?.data?.totalCount,
				pages: Math.ceil(data?.data?.totalCount / filter.limit),
				records: apiDataList,
			});
			setLoading(false);
		}
	};

	const handleRound = async (value, page, limit) => {
		console.log("round = ", value, page, limit);
		setError("");
		setRound(value);
		setLoading(true);
		const data = await getApiData(page, limit, {
			type: "round",
			quizId: quiz,
			roundId: value,
		});
		const apiDataList = data.data?.data?.map((item, index) => {
			return {
				index: index + 1,
				name: item?.user[0]?.name ? item?.user[0]?.name : "-",
				email: item?.user[0]?.email ? item?.user[0]?.email : "-",
				phone_number: item?.user[0]?.mobileNumber
					? item?.user[0]?.mobileNumber
					: "-",
				success_rate: item?.successRateOfAttempted
					? item?.successRateOfAttempted
					: "-",
				score: item?.score ? item?.score : "-",
				result: item?.result ? item?.result : "-",
				timeTaken: item?.timeTaken ? item?.timeTaken + ' sec' : "-",
			};
		});
		setTableData({
			from: (filter?.page - 1) * filter?.limit,
			to:
				filter?.page * filter?.limit > data?.data?.totalCount
					? data?.data?.totalCount
					: filter?.page * filter?.limit,
			total: data?.data?.totalCount,
			pages: Math.ceil(data?.data?.totalCount / filter?.limit),
			records: apiDataList,
		});
		setLoading(false);
	};

	const handleContest = async (value, page, limit) => {
		setError("");
		setContest(value);
		setLoading(true);
		const data = await getApiData(page, limit, {
			type: "persona",
			personaId: value,
		});
		const apiDataList = data.data.data.map((item, index) => {
			return {
				index: index + 1,
				name: item?.name ? item?.name : "-",
				email: item?.email ? item?.email : "-",
				city: item?.city ? item?.city : "-",
				contest: item?.persona?.name ? item?.persona?.name : "-",
			};
		});
		setTableData({
			from: (filter.page - 1) * filter.limit,
			to: data?.data?.totalCount,
			total: data?.data?.totalCount,
			pages: Math.ceil(data?.data?.totalCount / filter.limit),
			records: apiDataList,
		});
		setLoading(false);
	};

	const handleGenerate = async (_type, page, limit) => {
		console.log({ _type, page, limit });
		if (_type === "") {
			setError("Please select type");
			return;
		}

		// if (_type === "quiz" && quiz === "") {
		// 	setError("Please select contest");
		// 	return;
		// }

		// if (_type === "quiz_round" && (round === "" || quiz === "")) {
		// 	setError("Please select contest and round");
		// 	return;
		// }

		setLoading(true);
		if (_type === "participant") {
			const data = await getApiData(page, limit, {
				type: "participant",
			});
			const apiDataList = data?.data?.data?.map((item, index) => {
				return {
					index: index + 1,
					name: item?.name,
					email: item?.email,
					// gender: item.gender,
					city: item?.city,
				};
			});
			setTableData({
				from: (filter?.page - 1) * filter?.limit,
				to:
					filter?.page * filter?.limit > data?.data?.totalCount
						? data?.data?.totalCount
						: filter?.page * filter?.limit,
				total: data?.data?.totalCount,
				pages: Math.ceil(data?.data?.totalCount / filter?.limit),
				records: apiDataList,
			});
			setLoading(false);
		} else if (_type === "quiz") {
			const data = await getApiData(page, limit, {
				_type: "quiz",
				quizId: quiz,
			});
			const apiDataList = data?.data?.data?.map((item, index) => {
				return {
					index: index + 1,
					name: item?.user?.name,
					email: item?.user?.email,
					// gender: item.user.gender,
					phone_number: item?.user?.mobileNumber,
					attempted_round: item?.attemptedRounds,
				};
			});
			setTableData({
				from: (filter?.page - 1) * filter?.limit,
				to:
					filter?.page * filter?.limit > data?.data?.totalCount
						? data?.data?.totalCount
						: filter?.page * filter?.limit,
				total: data?.data?.totalCount,
				pages: Math.ceil(data?.data?.totalCount / filter?.limit),
				records: apiDataList,
			});
			setLoading(false);
		} else if (_type === "quiz_round") {
			const data = await getApiData(page, limit, {
				_type: "round",
				quizId: quiz,
				roundId: round,
			});
			const apiDataList = data?.data?.data?.map((item, index) => {
				return {
					index: index + 1,
					name: item?.user[0]?.name,
					email: item?.user[0]?.email,
					// gender: item.user[0].gender,
					phone_number: item?.user[0]?.mobileNumber,
					success_rate: item?.successRateOfAttempted,
					score: item?.score,
					result: item?.result,
					timeTaken: item?.timeTaken,
				};
			});
			setTableData({
				from: (filter?.page - 1) * filter?.limit,
				to:
					filter.page * filter?.limit > data?.data?.totalCount
						? data?.data?.totalCount
						: filter?.page * filter?.limit,
				total: data?.data?.totalCount,
				pages: Math.ceil(data?.data?.totalCount / filter?.limit),
				records: apiDataList,
			});
			setLoading(false);
		}
	};

	const _handleGenerate = async (page, limit) => {
		if (type === "") {
			setError("Please select type");
			return;
		}

		if (type === "quiz" && quiz === "") {
			setError("Please select a quiz");
			return;
		}

		if (type === "quiz_round" && (round === "" || quiz === "")) {
			setError("Please select a quiz and a round");
			return;
		}
		setLoading(true);

		if (type === "participant") {
			const data = await getApiData(page, limit, {
				type: "participant",
			});
			const apiDataList = data?.data?.data?.map((item, index) => {
				return {
					index: index + 1,
					name: item?.name ? item?.name : "-",
					email: item?.email ? item?.email : "-",
					// gender: item.gender,
					city: item?.city ? item?.city : "-",
				};
			});
			setTableData({
				from: (filter?.page - 1) * filter?.limit,
				to:
					filter.page * filter?.limit > data?.data?.totalCount
						? data?.data?.totalCount
						: filter?.page * filter?.limit,
				total: data?.data?.totalCount,
				pages: Math.ceil(data?.data?.totalCount / filter?.limit),
				records: apiDataList,
			});
			setLoading(false);
		} else if (type === "quiz") {
			const data = await getApiData(page, limit, {
				type: "quiz",
				quizId: quiz,
			});
			const apiDataList = data.data.data.map((item, index) => {
				return {
					index: index + 1,
					name: item?.user?.name,
					email: item?.user?.email,
					// gender: item.user.gender,
					phone_number: item?.user?.mobileNumber,
					attempted_round: item?.attemptedRounds,
				};
			});
			setTableData({
				from: (filter?.page - 1) * filter?.limit,
				to:
					filter.page * filter?.limit > data?.data?.totalCount
						? data?.data?.totalCount
						: filter?.page * filter?.limit,
				total: data?.data?.totalCount,
				pages: Math.ceil(data?.data?.totalCount / filter?.limit),
				records: apiDataList,
			});
			setLoading(false);
		} else if (type === "quiz_round") {
			const data = await getApiData(page, limit, {
				type: "round",
				quizId: quiz,
				roundId: round,
			});
			const apiDataList = data?.data?.data?.map((item, index) => {
				return {
					index: index + 1,
					name: item?.user[0]?.name,
					email: item?.user[0]?.email,
					// gender: item.user[0].gender,
					phone_number: item?.user[0]?.mobileNumber,
					success_rate: item?.successRateOfAttempted,
					score: item?.score,
					result: item?.result,
					timeTaken: item?.timeTaken,
				};
			});
			setTableData({
				from: (filter?.page - 1) * filter?.limit,
				to:
					filter?.page * filter?.limit > data?.data?.totalCount
						? data?.data?.totalCount
						: filter?.page * filter?.limit,
				total: data?.data?.totalCount,
				pages: Math.ceil(data?.data?.totalCount / filter?.limit),
				records: apiDataList,
			});
			setLoading(false);
		} else if (type === "persona") {
			const data = await getApiData(page, limit, {
				type: "persona",
				personaId: contest,
			});
			const apiDataList = data?.data?.data?.map((item, index) => {
				return {
					index: index + 1,
					name: item?.name,
					email: item?.email,
					// gender: item?.gender,
					city: item?.city,
					contest: item?.persona?.name,
				};
			});
			setTableData({
				from: (filter?.page - 1) * filter?.limit,
				to: data?.data?.totalCount,
				total: data?.data?.totalCount,
				pages: Math.ceil(data?.data?.totalCount / filter?.limit),
				records: apiDataList,
			});
			setLoading(false);
		}
	};

	// const convertToCSV = (data) => {
	// 	const csvRows = [];
	// 	const headers = Object.keys(data[0]);
	// 	csvRows.push(headers.join(","));

	// 	for (const row of data) {
	// 		const values = headers.map((header) => row[header]);
	// 		csvRows.push(values.join(","));
	// 	}

	// 	return csvRows.join("\n");
	// };

	const convertToCSV = (data) => {
		const csvRows = [];
		const headers = Object.keys(data[0]);
		csvRows.push(headers?.map(field => `"${field.replace(/"/g, '""')}"`).join(","));
	
		for (const row of data) {
			const values = headers.map(header => {
				const field = row[header];
				// If field is undefined (not present in the row), convert it to an empty string
				const value = (field === undefined || field === null) ? "" : field.toString();
				return `"${value.replace(/"/g, '""')}"`;
			});
			csvRows.push(values.join(","));
		}
	
		return csvRows.join("\n");
	};
	

	const handleExportCSV = async () => {
		console.log("tableData = ", tableData);
		const finalRecords = await getAllEntriesForCSV();

		console.log("finalRecords = ", finalRecords);

		if(!finalRecords) {
			return;
		}

		// console.log("finalRecords = ", finalRecords);
		const csvData = convertToCSV(finalRecords);
		console.log("csvData = ",csvData);
		// return;
		const csvBlob = new Blob([csvData], {
			type: "text/csv;charset=utf-8;",
		});
		const csvUrl = URL.createObjectURL(csvBlob);
		const link = document.createElement("a");
		link.href = csvUrl;
		link.target = "_blank";
		link.download = "reportData.csv";
		link.click();
	};

	const getAllEntriesForCSV = async () => {
		if (type === "") {
			setError("Please select type");
			return;
		}

		if (type === "persona" && contest === "") {
			setError("Please select a contest");
			return;
		}

		if (type === "quiz" && quiz === "") {
			setError("Please select a quiz");
			return;
		}

		if (type === "quiz_round" && (round === "" || quiz === "")) {
			setError("Please select a quiz and a round");
			return;
		}
		// setLoading(true);

		if (type === "participant") {
			const data = await getApiData(null, "all", {
				type: "participant",
			});
			const apiDataList = data?.data?.data?.map((item, index) => {
				return {
					index: index + 1,
					name: item?.name ? item?.name : "-",
					email: item?.email ? item?.email : "-",
					phoneNumber: item?.mobileNumber ? item?.mobileNumber : "",
					// gender: item.gender,
					utmSource: item?.utm_source ? item?.utm_source : "-",
					schoolName: item?.schoolName ? item?.schoolName : "-",
					schoolAddress: item?.schoolAddress ? item?.schoolAddress : "-",
					city: item?.city ? item?.city : "-",
					state: item?.state ? item?.state : "-",
					country:item?.country ? item?.country :"-",
					parentName: item?.parentName ? item?.parentName : "-",
					parentPhone: item?.parentContactNumber ? item?.parentContactNumber : "-",
					parentEmail: item?.parentEmail ? item?.parentEmail : "-",
					registrationId: item?.registrationId ? item?.registrationId : "-",
					AreYouGraduate: item?.isGraduate ? item?.isGraduate.toUpperCase() : "-",
					WantToPursueMaster: item?.isPursueMaster ? item?.isPursueMaster.toUpperCase() : "-",

				};
			});

			return apiDataList;

		} else if (type === "quiz") {
			const data = await getApiData(null, "all", {
				type: "quiz",
				quizId: quiz,
			});
			const apiDataList = data?.data?.data?.map((item, index) => {
				return {
					index: index + 1,
					name: item?.user?.name ? item?.user?.name : "-",
					email: item?.user?.email ? item?.user?.email : "-",
					// gender: item.user.gender,
					phoneNumber: item?.user?.mobileNumber ? item?.user?.mobileNumber : "-",
					attempted_round: item?.attemptedRounds ? item?.attemptedRounds : "-",
					utmSource: item?.user?.utm_source ? item?.user?.utm_source : "-",
					schoolName: item?.user?.schoolName ? item?.user?.schoolName : "-",
					schoolAddress: item?.user?.schoolAddress ? item?.user?.schoolAddress : "-",
					city: item?.user?.city ? item?.user?.city : "-",
					state: item?.user?.state ? item?.user?.state : "-",
					country:item?.user?.country ? item?.user?.country :"-",
					parentName: item?.user?.parentName ? item?.user?.parentName : "-",
					parentPhone: item?.user?.parentContactNumber ? item?.user?.parentContactNumber : "-",
					parentEmail: item?.user?.parentEmail ? item?.user?.parentEmail : "-",
					registrationId: item?.user?.registrationId ? item?.user?.registrationId : "-",
					AreYouGraduate: item?.user?.isGraduate ? item?.user?.isGraduate.toUpperCase() : "-",
					WantToPursueMaster: item?.user?.isPursueMaster ? item?.user?.isPursueMaster.toUpperCase() : "-"
				};
			});

			return apiDataList;

		} else if (type === "quiz_round") {
			const data = await getApiData(null, "all", {
				type: "round",
				quizId: quiz,
				roundId: round,
			});
			const apiDataList = data?.data?.data?.map((item, index) => {
				return {
					index: index + 1,
					name: item?.user[0]?.name ? item?.user[0]?.name : "-",
					email: item?.user[0]?.email ? item?.user[0]?.email : "-",
					// gender: item.user[0].gender,
					phoneNumber: item?.user[0]?.mobileNumber ? item?.user[0]?.mobileNumber : "-",
					success_rate: item?.successRateOfAttempted ? item?.successRateOfAttempted : "-",
					score: item?.score ? item?.score : "-",
					result: item?.result ? item?.result : "-",
					timeTaken: item?.timeTaken ? item?.timeTaken : "-",
					utmSource: item?.user[0]?.utm_source ? item?.user[0]?.utm_source : "-",
					schoolName: item?.user[0]?.schoolName ? item?.user[0]?.schoolName : "-",
					schoolAddress: item?.user[0]?.schoolAddress ? item?.user[0]?.schoolAddress : "-",
					city: item?.user[0]?.city ? item?.user[0]?.city : "-",
					state: item?.user[0]?.state ? item?.user[0]?.state : "-",
					country: item?.user[0]?.country ? item?.user[0]?.country : "-",
					parentName: item?.user[0]?.parentName ? item?.user[0]?.parentName : "-",
					parentPhone: item?.user[0]?.parentContactNumber ? item?.user[0]?.parentContactNumber : "-",
					parentEmail: item?.user[0]?.parentEmail ? item?.user[0]?.parentEmail : "-",
					registrationId: item?.user[0]?.registrationId ? item?.user[0]?.registrationId : "-",
					AreYouGraduate: item?.user[0]?.isGraduate ? item?.user[0]?.isGraduate.toUpperCase() : "-",
					WantToPursueMaster: item?.user[0]?.isPursueMaster ? item?.user[0]?.isPursueMaster.toUpperCase() : "-",
					testTakenIST: moment.utc(item?.createdAt).local().format('ddd MMM DD YYYY HH:mm:ss')
				};
			});

			return apiDataList;

		} else if (type === "persona") {
			const data = await getApiData(null, "all", {
				type: "persona",
				personaId: contest,
			});
			const apiDataList = data?.data?.data?.map((item, index) => {
				return {
					index: index + 1,
					name: item?.name ? item?.name :  "-",
					email: item?.email ? item?.email : "-",
					phoneNumber: item?.mobileNumber ? item?.mobileNumber : "-",
					// gender: item.gender,
					contest: item?.persona?.name ? item?.persona?.name : "-" ,
					utmSource: item?.utm_source ? item?.utm_source : "-",
					schoolName: item?.schoolName ? item?.schoolName : "-",
					schoolAddress: item?.schoolAddress ? item?.schoolAddress : "-",
					city: item?.city ? item?.city : "-",
					state: item?.state ? item?.state : "-",
					country: item?.country ? item?.country : "-",
					parentName: item?.parentName ? item?.parentName : "-",
					parentPhone: item?.parentContactNumber ? item?.parentContactNumber : "-",
					parentEmail: item?.parentEmail ? item?.parentEmail : "-",
					registrationId: item?.registrationId ? item?.registrationId : "-",
					AreYouGraduate: item?.isGraduate ? item?.isGraduate.toUpperCase() : "-",
					WantToPursueMaster: item?.isPursueMaster ? item?.isPursueMaster.toUpperCase() : "-"
					
				};
			});
			return apiDataList;
		}
	};

	return (
		<div className="page-content form-page">
			<div className="bg-dash-dark-2 py-4">
				<div className="container-fluid d-flex align-items-center justify-content-between">
					<h3 className="mb-0">Reports</h3>
					<button
						className="btn btn-primary ms-3"
						disabled={!(tableData?.records?.length > 0)}
						onClick={handleExportCSV}
					>
						<BiExport className="pb-1" />
						Export CSV
					</button>
				</div>
			</div>
			<BreadCrumb page="Reports" />

			<section className="tables py-0">
				<div className="container-fluid">
					<div className="row flex item-center">
						<div className="col-md-3">
							<label className="form-label pt-2">Type</label>
							<select
								className="form-select"
								value={type}
								onChange={(e) => {
									handleType(e.target.value);
									handleGenerate(
										e.target.value,
										filter.page,
										filter.limit
									);
								}}
							>
								<option value="" disabled>
									Please Select
								</option>
								<option value="participant">Participant</option>
								<option value="persona">Contest Wise</option>
								<option value="quiz">Quiz Wise</option>
								<option value="quiz_round">
									Quiz Round Wise
								</option>
							</select>
						</div>
						{type !== "" &&
							type !== "participant" &&
							type !== "contest" &&
							type !== "persona" && (
								<div className="col-md-3 d-flex align-item-center justify-content-between  flex-column">
									<label className="form-label pt-2">
										Quiz
									</label>
									<select
										className="form-select"
										value={quiz}
										onChange={(e) => {
											handleQuiz(
												e.target.value,
												filter.page,
												filter.limit,
												type
											);
										}}
									>
										<option value="" disabled selected>
											Please Select
										</option>
										{quizList.map((item, index) => {
											return (
												<option
													value={item._id}
													key={index}
												>
													{item.title}
												</option>
											);
										})}
									</select>
								</div>
							)}
						{type === "quiz_round" && (
							<div className="col-md-3">
								<label className="form-label pt-2">Round</label>
								<select
									className="form-select"
									value={round}
									onChange={(e) => {
										handleRound(
											e.target.value,
											filter.page,
											filter.limit
										);
									}}
								>
									<option value="" disabled selected>
										Please Select
									</option>
									{console.log("quizRound", quizRound)}
									{quizRound.map((item, index) => {
										return (
											<option value={item._id} key={index}>
												{item.title}
											</option>
										);
									})}
								</select>
							</div>
						)}
						{type === "persona" && (
							<div className="col-md-3">
								<label className="form-label pt-2">
									Contest
								</label>
								<select
									className="form-select"
									value={contest}
									onChange={(e) => {
										handleContest(
											e.target.value,
											filter.page,
											filter.limit
										);
									}}
								>
									<option value="" disabled selected>
										Please Select
									</option>
									{contestList.map((item, index) => {
										return (
											<option
												value={item._id}
												key={index}
											>
												{item.name}
											</option>
										);
									})}
								</select>
							</div>
						)}
						{error ? <p className="text-danger">{error}</p> : null}
					</div>

					{isLoading ? (
						<div
							className="d-flex justify-content-center align-items-center"
							style={{ marginTop: "130px" }}
						>
							<div className="page_loader" />
						</div>
					) : tableData.records?.length > 0 ? (
						<div className="row gy-4 mt-2">
							<div className="col-lg-12">
								<div className="card mb-0">
									<div className="card-header"></div>
									<div className="card-body pt-0">
										<div className="table-responsive">
											<table className="table mb-0 table-striped table-hover">
												<thead>
													<tr>
														{Object.keys(tableData.records[0]).map((item, index) => (
															<th className="bgDark" key={index}>
																{item.charAt(0).toUpperCase() + item.slice(1).replace("_"," ")}
															</th>
														))}
													</tr>
												</thead>
												<tbody>
													<Fragment>
														{tableData.records?.map(
															(item, index) => (
																<tr key={index}>
																	{Object.values(item).map(
																		(value, ind) => (
																			<td key={ind}>
																				{value ? value : "-"}
																			</td>
																		)
																	)}
																</tr>
															)
														)}
													</Fragment>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="d-flex justify-content-center mt-4">
							No Data Found
						</div>
					)}
					{tableData?.total > 10 && (
						<div className="paginationMain w-100 mb-4 mt-1">
							<div className="pageEntries">
								Showing {tableData.from + 1} to{" "}
								{tableData.to} of {tableData.total}{" "}
								entries
							</div>
							<div className="d-flex justify-content-center">
								<ReactPaginate
									breakLabel="..."
									initialPage={0}
									forcePage={filter.page - 1}
									onPageChange={(selected) =>{
										changeFilter("page", selected.selected + 1)}
									}
									pageCount={tableData.pages}
									renderOnZeroPageCount={null}
									activeClassName="active"
									containerClassName="pagination float-end"
									pageClassName="page-item"
									pageLinkClassName="page-link"
									previousLinkClassName="page-link"
									nextLinkClassName="page-link"
									previousClassName="page-item"
									nextClassName="page-item"
									breakClassName="page-item"
									breakLinkClassName="page-link"
									disabledClassName="disabled"
									previousLabel="«"
									nextLabel="»"
								/>
							</div>
						</div>
					)}
				</div>
			</section>
		</div>
	);
};

export default Reports;
