import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { uploadImageToAzure } from "../../Utils/ImageUpload";
import { FaTimesCircle } from "react-icons/fa";


function loadGoogleDriveImage (url)  {
	const isGoogleImage = url.includes('drive.google.com');

	if(isGoogleImage) {
		const fileId = url.split('/d/')[1].split('/')[0];
		return `https://drive.google.com/uc?export=view&id=${fileId}`;
	}

	return url;
} 

const QuizQuestionModal = ({
	show,
	modalClose,
	data = "",
	title = "Create Question",
	handleSubmit,
}) => {
 const [isLoading, setIsLoading] = useState(false);

	const validationSchema = Yup.object().shape({
		text: Yup.string().min(5, "Questin is too short!").required("Question is required"),
		type: Yup.string().required("Selection of Type is required"),
		complexity: Yup.string().required("Selection of Complexity is required"),
		time: Yup.string().required("Time is required"),
		option1: Yup.string().required("Option 1 is requried"),
		option2: Yup.string().required("Option 2 is requried"),
		option3: Yup.string().required("Option 3 is requried"),
		option4: Yup.string().required("Option 4 is requried"),
		checkboxGroup: Yup.array().min(1, 'Select at least one checkbox'),
		correctAnswer: Yup.string().required("Please select correct answer from above options"),
		url: Yup.string()
			.when("type", {
				is: (value) => value === "VIS",
				then: (schema) =>
					schema.required("Image is required"),
				otherwise: (schema) => schema,
			}),
	});

	const [initialValues, setInitialValues] = useState({
		text: "",
		type: "",
		complexity: "",
		time: null,
		url: "",
		option1: "",
		option2: "",
		option3: "",
		option4: "",
		correctAnswer: null,
	});

	const handleCorrectAnswer = (value) => {
		if (value !== formik.values.correctAnswer) {
			formik.setFieldValue("correctAnswer", value);
		} else {
			formik.setFieldValue("correctAnswer", null);
		}
	};
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (value, { setSubmitting }) => {
			setSubmitting(true);
			const request = {
				question: value.text,
				options: [
					value.option1,
					value.option2,
					value.option3,
					value.option4,
				],
				correctAnswer: value.correctAnswer,
				type: value.type,
				url: value.url,
				complexity: value.complexity,
				time: value.time,
			};
			if (!data) {
				handleSubmit(request);
			} else {
				handleSubmit(data._id || data.id, request);
			}

			setSubmitting(false);

			formik.resetForm();
			modalClose();
		},
	});

	useEffect(() => {
		if (data !== "") {
			setInitialValues({
				text: data?.question,
				type: data?.type,
				complexity: data?.complexity,
				time: data?.time,
				url: data?.url,
				option1: data?.options?.[0] ?? "",
				option2: data?.options?.[1] ?? "",
				option3: data?.options?.[2] ?? "",
				option4: data?.options?.[3] ?? "",
				correctAnswer: data?.correctAnswer,
			});
		}
	}, [data]);

	const handleImageUpload = async (e) => {
		setIsLoading(true);
		const url = await uploadImageToAzure(e.target.files[0]);
		formik.setFieldValue("url", url);
		setIsLoading(false);
	  };
	return (
		<Modal size="lg" show={show} onHide={modalClose}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={formik.handleSubmit}>
				<div className="d-flex justify-content-center" style={{ overflowY: 'auto', height: '500px' }}>
					<Modal.Body>
						<Container fluid>
							<Row>
								<Form.Group
									className="form-group mt-3"
									controlId="formBasicEmail"
								>
									<Form.Label>Text</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter text"
										autoComplete="off"
										name="text"
										value={formik.values.text}
										onChange={formik.handleChange}
										className="form-control mt-1"
									/>
									{formik.errors.text &&
									formik.touched.text ? (
										<Form.Text className="text-danger">
											{formik.errors.text}
										</Form.Text>
									) : null}
								</Form.Group>
							</Row>
							<Row>
								<Col className="col-4 p-2">
									<Form.Group
										className="form-group mt-3"
										controlId="formBasicComplexity"
									>
										<Form.Label>Complexity</Form.Label>
										<Form.Select
											name="complexity"
											value={formik.values.complexity}
											onChange={formik.handleChange}
										>
											<option>Select complexity</option>
											<option value="T">T</option>
											<option value="NE">NE</option>
											<option value="E">E</option>
										</Form.Select>
										{formik.errors.complexity &&
										formik.touched.complexity ? (
											<Form.Text className="text-danger">
												{formik.errors.complexity}
											</Form.Text>
										) : null}
									</Form.Group>
								</Col>
								<Col className="col-4 p-2">
									<Form.Group
										className="form-group mt-3"
										controlId="formBasicComplexity"
									>
										<Form.Label>Type</Form.Label>
										<Form.Select
											value={formik.values.type}
											onChange={formik.handleChange}
											name="type"
										>
											<option>Select type</option>
											<option value="VIS">VIS</option>
											<option value="TXT">TXT</option>
										</Form.Select>
										{formik.errors.type &&
										formik.touched.type ? (
											<Form.Text className="text-danger">
												{formik.errors.type}
											</Form.Text>
										) : null}
									</Form.Group>
								</Col>
								<Col className="col-4 p-2">
									<Form.Group
										className="form-group mt-3"
										controlId="formBasicComplexity"
									>
										<Form.Label>Time (in Sec)</Form.Label>
										<Form.Control
											type="number"
											placeholder="Enter name"
											autoComplete="off"
											name="time"
											min="1"
											value={formik.values.time}
											onChange={formik.handleChange}
											className="form-control mt-1"
										/>
										{formik.errors.time &&
										formik.touched.time ? (
											<Form.Text className="text-danger">
												{formik.errors.time}
											</Form.Text>
										) : null}
									</Form.Group>
								</Col>
							</Row>
							{formik.values.type === "VIS" && (
								<Row>
									<Form.Group
										controlId="formFile"
										className="mb-3"
									>
										<Form.Label>Upload Image</Form.Label>
										<Form.Control
											type="file"
											placeholder="select image.."
											autoComplete="off"
											name="url"
											accept="image/png, image/gif, image/jpeg, image/jpg"
											// value={formik.values.url}
											onChange={(e) => {
												handleImageUpload(e);
											}}
											className="form-control mt-1"
										/>
										{formik.errors.url &&
										formik.touched.url ? (
											<Form.Text className="text-danger">
												{formik.errors.url}
											</Form.Text>
										) : null}
									</Form.Group>
									{formik.values?.type === 'VIS' && formik.values?.url !== '' ? 
										<div
											className="position-relative mt-3"
											style={{ width: "100px", height: "auto" }}
										>
											<Image
												src={loadGoogleDriveImage(formik.values.url)}
												fluid
												alt="Preview"
												rounded
											/>
											<FaTimesCircle
												className="position-absolute top-0 end-0 mt-2 me-2 cursor-pointer"
												onClick={() => {
													formik.setFieldValue("url", "");
												}}
											/>
										</div>
									
									: null}
								</Row>
							)}
							<Row>
								<Col className="col-6 p-2">
									<Form.Group>
										<Form.Label>Option 1</Form.Label>
										<InputGroup className="mb-3">
											<InputGroup.Checkbox
												aria-label="Checkbox for following text input"
												checked={formik.values.correctAnswer === formik.values.option1 }
												onChange={() => {
													if (formik.values.option1) {
														handleCorrectAnswer(
															formik.values
																.option1
														);
													}
												}}
											/>
											<Form.Control
												name="option1"
												value={formik.values.option1}
												onChange={formik.handleChange}
												aria-label="Text input with checkbox"
											/>
										</InputGroup>
										{formik.errors.option1 &&
										formik.touched.option1 ? (
											<Form.Text className="text-danger">
												{formik.errors.option2}
											</Form.Text>
										) : null}
									</Form.Group>
								</Col>
								<Col className="col-6 p-2">
									<Form.Group>
										<Form.Label>Option 2</Form.Label>
										<InputGroup className="mb-3">
											<InputGroup.Checkbox
												aria-label="Checkbox for following text input"
												checked={
													formik.values
														.correctAnswer ===
													formik.values.option2
												}
												onChange={() => {
													if (formik.values.option2) {
														handleCorrectAnswer(
															formik.values
																.option2
														);
													}
												}}
											/>
											<Form.Control
												name="option2"
												value={formik.values.option2}
												onChange={formik.handleChange}
												aria-label="Text input with checkbox"
											/>
										</InputGroup>
										{formik.errors.option2 &&
										formik.touched.option2 ? (
											<Form.Text className="text-danger">
												{formik.errors.option2}
											</Form.Text>
										) : null}
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col className="col-6 p-2">
									<Form.Group>
										<Form.Label>Option 3</Form.Label>
										<InputGroup className="mb-3">
											<InputGroup.Checkbox
												aria-label="Checkbox for following text input"
												checked={
													formik.values
														.correctAnswer ===
													formik.values.option3
												}
												onChange={() => {
													if (formik.values.option3) {
														handleCorrectAnswer(
															formik.values
																.option3
														);
													}
												}}
											/>
											<Form.Control
												aria-label="Text input with checkbox"
												value={formik.values.option3}
												name="option3"
												onChange={formik.handleChange}
											/>
										</InputGroup>
										{formik.errors.option3 &&
										formik.touched.option3 ? (
											<Form.Text className="text-danger">
												{formik.errors.option3}
											</Form.Text>
										) : null}
									</Form.Group>
								</Col>
								<Col className="col-6 p-2">
									<Form.Group>
										<Form.Label>Option 4</Form.Label>
										<InputGroup className="mb-3">
											<InputGroup.Checkbox
												aria-label="Checkbox for following text input"
												checked={
													formik.values
														.correctAnswer ===
													formik.values.option4
												}
												onChange={() => {
													if (formik.values.option4) {
														handleCorrectAnswer(
															formik.values
																.option4
														);
													}
												}}
											/>
											<Form.Control
												name="option4"
												value={formik.values.option4}
												onChange={formik.handleChange}
												aria-label="Text input with checkbox"
											/>
										</InputGroup>
										{formik.errors.option4 &&
										formik.touched.option4 ? (
											<Form.Text className="text-danger">
												{formik.errors.option4}
											</Form.Text>
										) : null}
									</Form.Group>
								</Col>
							</Row>
							{formik.errors.correctAnswer &&
							formik.touched.correctAnswer ? (
								<Form.Text className="text-danger">
									{formik.errors.correctAnswer}
								</Form.Text>
							) : null}
						</Container>
					</Modal.Body>
				</div>
				<Modal.Footer className="mt-3">
					<Button variant="secondary" onClick={modalClose}>
						Close
					</Button>
					<Button
						variant="primary"
						type="submit"
						disabled={formik.isSubmitting || isLoading}
					>
						 {formik.isSubmitting || isLoading ? (
							<Fragment>
								<Spinner
									animation="border"
									size="sm"
									className="me-2"
								/>{" "}
								Please wait...
							</Fragment>
						) : (
							"Save"
						)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default QuizQuestionModal;