import React, { Fragment, useEffect, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import ModalCreateSponser from "../../components/sponser/SponserModel";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/common/BreadCrumb";
import { Button } from "react-bootstrap";
import AxiosRequest from "../../Utils/AxiosRequest";

const Sponser = () => {
	const [sponsers, setSponsers] = useState({});
	const [showSponserModel, setShowSponserModel] = useState(false);
	const [showEditSponserModel, setShowEditSponserModel] = useState(false);
	const [editSponserData, setEditSponserData] = useState({});
	const [loading, setLoading] = useState(false);
	const [filter, setFilters] = useState({
		limit: 5,
		page: 1,
		search: "",
	});
	const [sort, setSort] = useState({
		sortBy: "name",
		sortAt: "asc",
	});

	useEffect(() => {
		fetchData();
	}, [filter, sort]);
	const handleSort = async (value) => {
		if (value === "name_asc") {
			setSort({
				sortBy: "name",
				sortAt: "asc",
			});
		} else if (value === "name_desc") {
			setSort({
				sortBy: "name",
				sortAt: "desc",
			});
		}
	};

	const fetchData = async () => {
		try {
			setLoading(true);
			const res = await AxiosRequest(
				`/admin/sponser?limit=${filter.limit}&page=${filter.page}&search=${filter.search}&sortBy=${sort.sortBy}&sortAt=${sort.sortAt}`
			);
			setSponsers({
				totalCount: res?.data?.data?.totalCount,
				data: res?.data?.data?.data,
			});
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};
	const changeFilter = (name, value) => {
		setFilters({ ...filter, [name]: value });
		const apiPayload = { ...filter, [name]: value };
	};

	const handleEditSponser = (data) => {
		try {
			setShowEditSponserModel(true);
			setEditSponserData(data);
		} catch (err) {
			console.log("err", err);
		}
	};

	const handleDeleteSponser = (data) => {
		try {
			Swal.fire({
				icon: "error",
				title: "Are you sure?",
				text: "You want to delete this sponser?",
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: "#dc3545",
				confirmButtonText: "Yes",
				cancelButtonText: "No",
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						const response = await AxiosRequest.delete(
							`/admin/sponser/${data._id}`
						);

						if (response.data.status) {
							Swal.fire({
								icon: "success",
								title: "Sponser has been deleted successfully!",
								confirmButtonColor: "#8d65db",
							});
							fetchData();
						} else {
							Swal.fire({
								icon: "error",
								title: "Error in deleting sponser. Please try again!",
								confirmButtonColor: "#8d65db",
							});
						}
					} catch (error) {
						console.log("Something went wrong. Please try again.");
					}
				}
			});
		} catch (err) {
			console.log("err", err);
		}
	};

	return (
		<div className="page-content form-page">
			{/* Page Header*/}
			<div className="bg-dash-dark-2 py-4">
				<div className="container-fluid d-flex align-items-center justify-content-between">
					<h3 className="mb-0">Sponsers</h3>
					<button
						className="btn btn-primary ms-3"
						onClick={() => {
							setShowSponserModel(true);
						}}
					>
						Add Sponser
					</button>
				</div>
			</div>

			<ModalCreateSponser
				modalClose={() => {
					setShowSponserModel(false);
					fetchData();
				}}
				show={showSponserModel}
			/>
			<ModalCreateSponser
				modalClose={() => {
					setShowEditSponserModel(false);
					fetchData();
				}}
				show={showEditSponserModel}
				data={editSponserData}
				title="Edit Sponser"
			/>

			{/* Breadcrumb*/}
			<BreadCrumb page="Sponsers" />

			<section className="tables py-0">
				<div className="container-fluid">
					<div className="row flex item-center">
						<div className="col-md-4">
							<label className="form-label pt-2">
								&nbsp;&nbsp;
							</label>
							<input
								type="text"
								placeholder="Search"
								className="form-control "
								aria-label="Search"
								onChange={(e) => {
									changeFilter("search", e.target.value);
								}}
							/>
						</div>
						<div className="col-md-5"></div>
						<div className="col-md-3">
							<label className="form-label pt-2">Filter</label>
							<select
								className="form-select"
								onChange={(e) => {
									handleSort(e.target.value);
								}}
							>
								<option value={"name_asc"}>
									Sort By Name (A-Z)
								</option>
								<option value={"name_desc"}>
									Sort By Name (Z-A)
								</option>
							</select>
						</div>
					</div>
					<div className="row gy-4 mt-2">
						<div className="col-lg-12">
							{loading ? (
								<div
									className="d-flex justify-content-center align-items-center"
									style={{ marginTop: "130px" }}
								>
									<div className="page_loader" />
								</div>
							) : (
								<div className="card mb-0">
									<div className="card-header"></div>
									<div className="card-body pt-0">
										<div className="row">
											{sponsers?.data?.map((sponser) => (
												<div className="col-2 m-3">
													<div className="card testimonial-card mt-2 mb-3 shadow">
														<div className="card-up aqua-gradient"></div>
														<div className="imgView mx-auto mt-3">
															<img
																src={
																	sponser.imageUrl
																}
																className="img-fluid"
																alt="woman imgView"
																style={{
																	// width: "100%",
																	// height: "60px",
																	maxWidth:
																		"80px",
																	maxHeight:
																		"80px",
																	height: "auto",
																}}
															/>
														</div>
														<div className="card-body text-center">
															<h4 className="card-title font-weight-bold">
																{sponser.name}
															</h4>
															<h5 className="card-subtitle">
																<span class="badge bg-secondary">
																	{sponser.type
																		.charAt(
																			0
																		)
																		.toUpperCase() +
																		sponser.type.slice(
																			1
																		)}{" "}
																</span>
															</h5>
															<hr />
															<div className="d-flex justify-content-center">
																<FaUserEdit
																	style={{
																		fontSize:
																			"20px",
																		color: "#7385C9",
																		cursor: "pointer",
																	}}
																	onClick={() => {
																		handleEditSponser(
																			sponser
																		);
																	}}
																/>
																<MdDelete
																	style={{
																		fontSize:
																			"20px",
																		marginLeft:
																			"10px",
																		color: " #BD5357",
																		cursor: "pointer",
																	}}
																	onClick={() => {
																		handleDeleteSponser(
																			sponser
																		);
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
											))}
										</div>

										{sponsers.data?.length ? (
											<div className="text-center d-flex justify-content-between">
												<div className="d-flex">
													<Button
														variant="dark"
														className="mx-2 text-white"
														style={{
															backgroundColor:
																"#22252a",
														}}
														onClick={() =>
															changeFilter(
																"page",
																1
															)
														}
														disabled={
															filter.page === 1
														}
													>
														{"<<"}
													</Button>
													<Button
														variant="dark"
														className="mx-2 bg-[#db6574] text-white"
														onClick={() =>
															changeFilter(
																"page",
																filter.page - 1
															)
														}
														disabled={
															filter.page === 1
														}
														style={{
															backgroundColor:
																"#22252a",
														}}
													>
														{"<"}
													</Button>
													<Button
														className="mx-2 bg-[#db6574] text-white"
														onClick={() =>
															changeFilter(
																"page",
																filter.page + 1
															)
														}
														disabled={
															filter.page ===
															Math.ceil(
																sponsers.totalCount /
																	filter.limit
															)
														}
														style={{
															backgroundColor:
																"#db6574",
														}}
													>
														{">"}
													</Button>
													<Button
														onClick={() => {
															changeFilter(
																"page",
																Math.ceil(
																	sponsers.totalCount /
																		filter.limit
																)
															);
														}}
														disabled={
															filter.page ===
															Math.ceil(
																sponsers.totalCount /
																	filter.limit
															)
														}
														className="mx-2 text-white"
														style={{
															backgroundColor:
																"#db6574",
														}}
													>
														{">>"}
													</Button>
													<span className="mx-2 d-flex align-items-center">
														Page
														<strong>
															{filter.page} of{" "}
															{Math.ceil(
																sponsers.totalCount /
																	filter.limit
															)}
														</strong>
													</span>
													<div className="d-flex align-items-center">
														<span>
															| Go to page:
														</span>
														<input
															type="number"
															max={Math.ceil(
																sponsers.totalCount /
																	filter.limit
															)}
															min={1}
															defaultValue={
																filter.page
															}
															onChange={(e) => {
																if (
																	e.target
																		.value <=
																		sponsers.pages &&
																	e.target
																		.value >=
																		1
																) {
																	const page =
																		e.target
																			.value
																			? Number(
																					e
																						.target
																						.value
																			  )
																			: 0;
																	changeFilter(
																		"page",
																		e.target
																			.value
																	);
																} else {
																	changeFilter(
																		"page",
																		1
																	);
																}
															}}
															className="form-control ms-2"
															style={{
																width: "50px",
															}}
														/>
													</div>
												</div>
												<div>
													<select
														value={filter.limit}
														onChange={(e) => {
															changeFilter(
																"limit",
																e.target.value
															);
														}}
														className="form-select"
													>
														{[5, 10, 15].map(
															(pageSize) => (
																<option
																	key={
																		pageSize
																	}
																	value={
																		pageSize
																	}
																>
																	Show{" "}
																	{pageSize}
																</option>
															)
														)}
													</select>
												</div>
											</div>
										) : null}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
			{/* Page Footer*/}
		</div>
	);
};

export default Sponser;
