import { toast } from "react-toastify";
import AxiosRequest from "../../Utils/AxiosRequest";
import { quizState } from "./quiz.slice";

export const createQuizDetail =
  (values, setLoading, handleNext) => async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await AxiosRequest.post("admin/quiz", values);

      dispatch(
        quizState({
          key: "quizDetail",
          value: data.data,
        })
      );
      handleNext();
      setLoading(false);
    } catch (err) {
      toast.error(err.response.data.message);
      setLoading(false);
    }
  };

export const createRounds =
  (values, setLoading, handleNext) => async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await AxiosRequest.post(
        "admin/bulk-round-import",
        values
      );
      dispatch(
        quizState({
          key: "rounds",
          value: data.data,
        })
      );
      setLoading(false);
      handleNext();
    } catch (err) {
      toast.error(err.response.data.message);
      setLoading(false);
    }
  };

export const createQuestions =
  (values, setLoading, handleNext) => async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await AxiosRequest.post(
        "admin/bulk-question-import",
        values
      );
      dispatch(
        quizState({
          key: "questions",
          value: data.data,
        })
      );
      setLoading(false);
      handleNext("/quiz");
    } catch (err) {
      toast.error(err.response.data.message);
      setLoading(false);
    }
  };

export const personaList = async () => {
  try {
    const { data } = await AxiosRequest.get("admin/persona-list");
    return data.data;
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const sponserList = async () => {
  try {
    const { data } = await AxiosRequest.get("admin/sponsor-list");
    return data.data;
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const resetQuizData = () => async (dispatch) => {
  console.log("Data",resetQuizData);
  // return false;
  try {
    dispatch(
      quizState([
        { key: "quizDetail", value: {} },
        { key: "rounds", value: [] },
        { key: "questions", value: [] },
      ])
    );
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
