import React from "react";
import { Button, Modal, Table } from "react-bootstrap";

const ErrorMessageForQuestion = ({ show, modalClose, data }) => {
	return (
		<Modal size="xl" show={show} onHide={modalClose} scrollable centered>
			<Modal.Header closeButton>
				<Modal.Title>Error Details</Modal.Title>
			</Modal.Header>

			<div className="d-flex justify-content-center">
				<Modal.Body>
					<Table hover responsive>
						<thead>
							<tr>
								<th>Row</th>
								<th>Question</th>
								<th>Error</th>
							</tr>
						</thead>
						<tbody>
							{data?.length > 0 ? 
                                data?.map((error, index) => {
                                    const match = error?.match(/"(.+)" \(Row (\d+)\): (.+)/);
                                    const question = match[1];
                                    const row = match[2];
                                    const fullError = match[3];

                                    return (
                                        <tr key={index}>
                                            <td>{row}</td>
                                            <td>{question}</td>
                                            <td style={{color: "rgb(189, 83, 87)"}}>{fullError}</td>
                                        </tr>
                                    );
								})
							: null}
						</tbody>
					</Table>
				</Modal.Body>
			</div>
			<Modal.Footer className="mt-3">
				<Button variant="secondary" onClick={modalClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ErrorMessageForQuestion;
