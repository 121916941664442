import React from "react";
import { Button, Modal } from "react-bootstrap";

const ViewAdminProfile = ({ show, modalClose, data = "" }) => {
	return (
		<Modal size="lg" show={show} onHide={modalClose}>
			<Modal.Header closeButton>
				<Modal.Title>View Profile</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="container-fluid">
					<div className="row">
						<div className="form-group">
							<div className="form-label">Name</div>
							<div type="text" className="form-control mt-1">
								{data?.name}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 p-2">
							<div className="form-group mt-3">
								<div className="form-label">Email</div>
								<div type="text" className="form-control mt-1">
									{data?.email}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className="mt-3">
				<Button
					variant="secondary"
					onClick={() => {
						modalClose();
					}}
				>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ViewAdminProfile;
