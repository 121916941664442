import React, { lazy } from "react";
import { ProtectedRoute } from "./ProtectedRoutes";
import Login from "../pages/auth/Login"
import Activation from "../pages/auth/Activation"
import AuthLayout from "../layouts/authLayout"
import Dashboard from "../pages/dashboard"
import MainLayout from "../layouts/mainLayout"
import Users from "../pages/users"
import DeletedUsers from "../pages/users/ReactivationRequest"
import Quiz from "../pages/quiz"
import QuizCreate from "../pages/quiz/create"
import QuizView from "../pages/quiz/view"
import Reports from "../pages/reports"
import Sponser from "../pages/sponser"
import Persona from "../pages/persona"

const routes = [
	{
		path: "/",
		element: <AuthLayout />,
		children: [
			{
				path: "/",
				element: <Login title="BrainPulses - Login" />,
			},
			{
				path: "/activation",
				element: <Activation title="BrainPulses - Activation" /> 
			}
		],
	},
	{
		path: "",
		element: (
			<ProtectedRoute>
				<MainLayout />
			</ProtectedRoute>
		),
		children: [
			{
				path: "/dashboard",
				element: <Dashboard title="BrainPulses - Dashboard" />,
			},
			{
				path: "/users",
				element: <Users title="BrainPulses - Users" />,
			},
			{
				path: "/reactivation-request",
				element: <DeletedUsers title="BrainPulses - Reactivation Request" />
			},
			{
				path: "/quiz",
				element: <Quiz title="BrainPulses - Quiz" />,
			},
			{
				path: "quiz/create",
				element: <QuizCreate title="BrainPulses - Quiz Create" mode="create" />,
			},
			{
				path: "quiz/:id",
				element: <QuizView title="BrainPulses - Quiz View" />,
			},
			{
				path: "quiz/edit/:id",
				element: <QuizCreate title="BrainPulses - Quiz Edit" mode="edit" />,
			},
			{
				path: "/reports",
				element: <Reports title="BrainPulses - Reports" />,
			},
			{
				path: "/sponsers",
				element: <Sponser title="BrainPulses - Sponser" />,
			},
			{
				path: "/contest",
				element: <Persona title="BrainPulses - Contest" />,
			},
		],
	},
];

export default routes;
