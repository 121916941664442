import { useFormik } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Image, Modal, Row, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import AxiosRequest from "../../Utils/AxiosRequest";
import { toast } from "react-toastify";
import { FaTimesCircle } from "react-icons/fa";
import { uploadImageToAzure } from "../../Utils/ImageUpload";
import Autocomplete from "react-autocomplete";

const ModalCreateUser = ({
	show,
	modalClose,
	data = "",
	title = "Create User",
	pageData,
	fetchData,
}) => {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const validationSchema = Yup.object().shape(
		{
			name: Yup.string().min(5, "Please enter min 5 characters long name").required("Name is required"),
			city: Yup.string().required("City is required"),
			institute: Yup.string().required("Institute selection is required"),
			persona: Yup.string().required("Contest selection is required"),
			email: Yup.string().email("Invalid email address")
				.when("mobileNumber", {
					is: (value) => !value || value.trim() === "",
					then: (schema) => schema.required("Email is required"),
					otherwise: (schema) => schema,
			}),
			mobileNumber: Yup.string().matches(phoneRegExp, "Invalid mobile number")
				.when("email", {
					is: (value) => !value || value.trim() === "",
					then: (schema) =>
						schema.required("Mobile number is required"),
					otherwise: (schema) => schema,
			}),
			// participantName: Yup.string().min(5, "Please enter min 5 characters long participant").required("Participant name is required"),
			schoolName: Yup.string().min(5, "Please enter min 5 characters long school name"),
			schoolAddress: Yup.string().min(5, "Please enter min 5 characters long address"),
			parentName: Yup.string().min(5, "Please enter min 5 characters long parent name"),
			parentContactNumber: Yup.string().matches(phoneRegExp, "Invalid contact number"),
			parentEmail: Yup.string().email("Invalid email address")
			// photo: Yup.mixed().test('fileType', 'Only image files are allowed', (value) => {
			// 	console.log(value, "value")
			// 	if (value) {
			// 		const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
			// 		return supportedFormats.includes(value.type);
			// 	}
			// 	return false; // Allow empty file input
			// 	}),
		},
		[["email", "mobileNumber"]]
	);

	const [initialValues, setInitialValues] = useState({
		name: "",
		email: "",
		city: "",
		institute: "",
		persona: null,
		isVerified: false,
		mobileNumber: "",
		photo: "",
		// participantName: "",
		schoolName: "",
		schoolAddress: "",
		parentName: "",
		parentContactNumber: "",
		parentEmail: "",
		registrationId: ""
	});
	const apiCalledRef = useRef(false);
	const [persona, setPersona] = useState([]);
	const [institute, setInstitutes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [imageError, setImageError] = useState("");

	const handleImageUpload = async (e) => {
		setIsLoading(true);
		setImageError("");
		const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];

		if(supportedFormats.includes(e.target.files[0].type)) {
			const url = await uploadImageToAzure(e.target.files[0]);
			formik.setFieldValue("photo", url);
			setIsLoading(false);
		}else {
			formik.setFieldValue("photo", "");
			setImageError("Only image files are allowed");
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (data) {
			setInitialValues({
				name: data.name,
				email: data.email,
				city: data.city,
				mobileNumber: data.mobileNumber,
				institute: data.institute?.name,
				persona: data.persona?._id,
				isVerified: data.isVerified,
				photo: data.photo,
				// participantName: data?.participantName,
				schoolName: data?.schoolName,
				schoolAddress: data?.schoolAddress,
				parentName: data?.parentName,
				parentContactNumber: data?.parentContactNumber,
				parentEmail: data?.parentEmail,
				registrationId: data?.registrationId
			});
		}
	}, [data]);

	const fetchPersona = async () => {
		const res = await AxiosRequest(`/admin/persona?sortBy=name&sortAt=asc`);
		setPersona(res?.data?.data?.data || []);
	};

	const fetchInstitute = async () => {
		const res = await AxiosRequest(`/institute?sortBy=name&sortAt=asc`);
		setInstitutes(res?.data?.data || []);
	};

	useEffect(() => {
		if (!apiCalledRef.current) {
			fetchPersona();
			fetchInstitute();
			apiCalledRef.current = true;
		}
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (value, { setSubmitting }) => {
			console.log("value", value);
			
			setSubmitting(true);
			if (value.email === "" && value.mobileNumber) {
				delete value.email;
			}
			try {				
				if (data) {
					const userUpdate = await AxiosRequest.patch(
						`/admin/user/${data._id}`,
						{
							...value,
							countryCode: "+91",
							gender: "male",
						}
					);

					if (userUpdate.data.status) {
						fetchData(pageData);
						modalClose();
						formik.resetForm();
						setSubmitting(false);
						setInitialValues(initialValues);
						toast.success("User updated successfully!");
					} else {
						toast.error(
							"Error in updating user. Please try again!"
						);
					}
				} else {	
					if(value?.registrationId?.trim()) {
						const isExist = await AxiosRequest.post(`/admin/user/check-registrationId-admin`, {registrationId: value?.registrationId?.trim()})	
						console.log(isExist);
					
						if(!isExist?.data?.data?.status) {
							setSubmitting(false)
							toast.error(isExist?.data?.data?.message)
							return false;
						}
					}

					const res = await AxiosRequest.post("/admin/user", {
						...value,
						countryCode: "+91",
						gender: "male",
					}).catch((err) => {
						toast.error(err.response?.data?.message);
					});

					if (res.data.status) {
						fetchData(pageData);
						modalClose();
						setSubmitting(false);
						setInitialValues(initialValues);
						formik.resetForm();
						toast.success("User created successfully!");
					} else {
						toast.error(
							"Error in creating user. Please try again!"
						);
					}
				}
			} catch (err) {
				// toast.error(err.message);
				setSubmitting(false);
			}
		},
	});

	return (
		<Modal size="lg" show={show} onHide={modalClose}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={formik.handleSubmit}>
				<div className="d-flex justify-content-center " style={{ overflowY: 'auto', height: '700px' }}>
					<Modal.Body>

						<Form.Group controlId="formLogoImage" className="form-group"
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column"
							}}
						>
							{!formik.values.photo ? (
								<>
									<label htmlFor="upload-button" style={{height: "150px", width: "132px"}}>
										{/* <span className="fa-stack fa-2x mt-1 mb-3">
											<i className="fas fa-circle fa-stack-2x" style={{fontSize: "130px"}} />
											<i className="fas fa-user fa-stack-1x fa-inverse" style={{fontSize: "30px", color:"#22252a"}} />
										</span> */}
										{/* <div> */}
										<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9ZM12 20.5C13.784 20.5 15.4397 19.9504 16.8069 19.0112C17.4108 18.5964 17.6688 17.8062 17.3178 17.1632C16.59 15.8303 15.0902 15 11.9999 15C8.90969 15 7.40997 15.8302 6.68214 17.1632C6.33105 17.8062 6.5891 18.5963 7.19296 19.0111C8.56018 19.9503 10.2159 20.5 12 20.5Z" fill="#fff"></path> </g></svg>
										<h5 className="text-center" style={{
											fontSize: "0.9rem",
											color: "#eef3ff !important",
											fontWeight: "400"
										}}>Upload your photo</h5>
									</label>
									<Form.Control
										type="file"
										accept="image/*"
										id="upload-button"
										style={{ display: "none" }}
										onChange={(e) => {
											handleImageUpload(e);
										}}
										onBlur={formik.handleBlur}
									/>
									{imageError && (
										<div 
											style={{display: "block", width: "100%", textAlign: "center"}}
										>
											<Form.Text className="text-danger validation-message">
												{imageError}
											</Form.Text>
										</div>
									)}
								</>
							): null}
							{formik.values?.photo && (
								<>
									<div className="position-relative mt-3">
										<img src={formik.values.photo} alt="dummy" width="150" height="150" style={{
											borderRadius:"50%"
										}}  />
										<FaTimesCircle
											className="position-absolute mt-2 me-2 cursor-pointer"
											style={{
												right: "56px",
												top: "133px",
												color: "#BD5357",
												fontSize: "18px"
											}}
											onClick={() => {
												formik.setFieldValue("photo", "");
											}}
										/>
									</div>
								</>
							)}
						</Form.Group>

						<Form.Group
							className="form-group mt-1"
							// className={`form-group ${formik.values.photo ? 'mt-3 ' : 'mt-5'}`}
							controlId="formBasicEmail"
						>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter name"
								autoComplete="off"
								name="name"
								value={formik.values.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								className="form-control mt-1 form-field"
							/>
							{formik.errors.name && formik.touched.name ? (
								<Form.Text className="text-danger validation-message ">
									{formik.errors.name}
								</Form.Text>
							) : null}
						</Form.Group>

						<Form.Group
							className="form-group mt-3"
							controlId="formBasicEmail"
						>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter Email"
								autoComplete="off"
								name="email"
								value={formik.values.email}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								className="form-control mt-1 form-field"
							/>
							{formik.errors.email && formik.touched.email ? (
								<Form.Text className="text-danger validation-message">
									{formik.errors.email}
								</Form.Text>
							) : null}
						</Form.Group>

						<Form.Group
							className="form-group mt-3"
							controlId="formBasicEmail"
						>
							<Form.Label>City</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter City"
								autoComplete="off"
								name="city"
								value={formik.values.city}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								className="form-control mt-1 form-field"
							/>
							{formik.errors.city && formik.touched.city ? (
								<Form.Text className="text-danger validation-message">
									{formik.errors.city}
								</Form.Text>
							) : null}
						</Form.Group>

						<Form.Group className="form-group mt-3">
							<Form.Label className="pt-2">
								Educational Institution
							</Form.Label>

							<Autocomplete
								wrapperStyle={{ display: "block !important", width: "50%" }}
								inputProps={{
									className: "form-select",
									style: {
										width: "50% !important",
									},
									maxLength: 60
								}}
								getItemValue={(item) => item.name}
								items={institute}
								shouldItemRender={(item, value) =>
									item?.name
										?.toLowerCase()
										?.indexOf(value?.toLowerCase()) > -1
								}
								renderItem={(item, isHighlighted) => (
									<div
										className="form-control"
										style={{
											background: isHighlighted
												? "#8d65db"
												: "",
												color: isHighlighted
												? "#ffffff"
												: "",
										}}
									>
										{item.name}
									</div>
								)}
								onChange={(val) => {
									formik.setFieldValue(
										"institute",
										val.target.value
									);
								}}
								onBlur={formik.handleBlur}
								name="institute"
								value={formik.values.institute}
								onSelect={(val) =>
									formik.setFieldValue("institute", val)
								}
							/>
							{formik.errors.institute &&
							formik.touched.institute ? (
								<Form.Text className="text-danger validation-message">
									{formik.errors.institute}
								</Form.Text>
							) : null}
						</Form.Group>

						<Form.Group
							className="form-group mt-3"
							controlId="formBasicEmail"
						>
							<Form.Label>Mobile Number</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter mobile number"
								autoComplete="off"
								name="mobileNumber"
								value={formik.values.mobileNumber}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								className="form-control mt-1 form-field"
							/>
							{formik.errors.mobileNumber &&
							formik.touched.mobileNumber ? (
								<Form.Text className="text-danger validation-message">
									{formik.errors.mobileNumber}
								</Form.Text>
							) : null}
						</Form.Group>

						<Form.Group className="form-group mt-3">
							<Form.Label className="pt-2">Contest</Form.Label>
							<Form.Select
								className="w-50 form-field"
								style={{ overflowY: "scroll" }}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								name="persona"
								value={formik.values.persona}
							>
								<option disabled selected value={null}>
									Select Contest
								</option>
								{persona.map((item, index) => (
									<option
										value={item._id}
										id={item.name}
										key={index}
									>
										{item.name}
									</option>
								))}
							</Form.Select>
							{formik.errors.persona && formik.touched.persona ? (
								<Form.Text className="text-danger validation-message">
									{formik.errors.persona}
								</Form.Text>
							) : null}
						</Form.Group>
						
						{/* <Form.Group
							controlId="formLogoImage"
							className="form-group mt-3"
						>
							<Form.Label>Photo</Form.Label>
							<Form.Control
								type="file"
								accept="image/*"
								onChange={(e) => {
									handleImageUpload(e);
								}}
								onBlur={formik.handleBlur}
							/>
							{formik.errors.photo && formik.touched.photo ? (
								<Form.Text className="text-danger validation-message">
									{formik.errors.photo}
								</Form.Text>
							) : null}

							{formik.values.photo && (
								<div
									className="position-relative mt-3"
									style={{ width: "100px", height: "auto" }}
								>
									<Image
										src={formik.values.photo}
										fluid
										alt="Preview"
										rounded
									/>
									<FaTimesCircle
										className="position-absolute top-0 end-0 mt-2 me-2 cursor-pointer"
										onClick={() => {
											formik.setFieldValue("photo", "");
										}}
									/>
								</div>
							)}
						</Form.Group> */}

						<Row className="mt-3">
							{/* <Col xl={6} md={12}>
								<Form.Group
									className="form-group mt-1"
									controlId="formBasicEmail"
								>
									<Form.Label>Participant Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="Participant Name"
										autoComplete="off"
										name="participantName"
										value={formik?.values?.participantName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="form-control mt-1 form-field"
									/>
									{formik.errors.participantName &&
									formik.touched.participantName ? (
										<Form.Text className="text-danger validation-message ">
											{formik.errors.participantName}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col> */}
							<Col xl={6} md={12}>
								<Form.Group
									className="form-group mt-1"
									controlId="formBasicEmail"
								>
									<Form.Label>School Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="School Name"
										autoComplete="off"
										name="schoolName"
										value={formik?.values?.schoolName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="form-control mt-1 form-field"
									/>
									{formik.errors.schoolName && formik.touched.schoolName ? (
										<Form.Text className="text-danger validation-message ">
											{formik.errors.schoolName}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
							<Col xl={6} md={12}>
								<Form.Group
									className="form-group mt-1"
									controlId="formBasicEmail"
								>
									<Form.Label>School Address</Form.Label>
									<Form.Control
										type="text"
										placeholder="School Address"
										autoComplete="off"
										name="schoolAddress"
										value={formik?.values?.schoolAddress}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="form-control mt-1 form-field"
									/>
									{formik.errors.schoolAddress && formik.touched.schoolAddress ? (
										<Form.Text className="text-danger validation-message ">
											{formik.errors.schoolAddress}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col xl={6} md={12}>
								<Form.Group
									className="form-group mt-1"
									controlId="formBasicEmail"
								>
									<Form.Label>Parent Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="Parent Name"
										autoComplete="off"
										name="parentName"
										value={formik?.values?.parentName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="form-control mt-1 form-field"
									/>
									{formik.errors.parentName && formik.touched.parentName ? (
										<Form.Text className="text-danger validation-message ">
											{formik.errors.parentName}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
							<Col xl={6} md={12}>
								<Form.Group
									className="form-group mt-1"
									controlId="formBasicEmail"
								>
									<Form.Label>Parent Contact Number</Form.Label>
									<Form.Control
										type="text"
										placeholder="Parent Contact Number"
										autoComplete="off"
										name="parentContactNumber"
										value={formik?.values?.parentContactNumber}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="form-control mt-1 form-field"
									/>
									{formik.errors.parentContactNumber && formik.touched.parentContactNumber ? (
										<Form.Text className="text-danger validation-message ">
											{formik.errors.parentContactNumber}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
						</Row>
									
						<Row className="mt-3">
							<Col xl={6} md={12}>
								<Form.Group
									className="form-group mt-3"
									controlId="formBasicEmail"
								>
									<Form.Label>Parent Email</Form.Label>
									<Form.Control
										type="email"
										placeholder="Parent Email"
										autoComplete="off"
										name="parentEmail"
										value={formik.values.parentEmail}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="form-control mt-1 form-field"
									/>
									{formik.errors.parentEmail && formik.touched.parentEmail ? (
										<Form.Text className="text-danger validation-message">
											{formik.errors.parentEmail}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
							<Col xl={6} md={12}>
								<Form.Group
									className="form-group mt-1"
									controlId="formBasicEmail"
								>
									<Form.Label>Registration ID</Form.Label>
									<Form.Control
										type="text"
										placeholder="Registration ID"
										autoComplete="off"
										name="registrationId"
										readOnly={data}
										value={formik?.values?.registrationId}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										className="form-control mt-1 form-field"
									/>
									{formik.errors.registrationId &&
									formik.touched.registrationId ? (
										<Form.Text className="text-danger validation-message ">
											{formik.errors.registrationId}
										</Form.Text>
									) : null}
								</Form.Group>
							</Col>
						</Row>
						
						<Form.Group className="form-group mt-3">
							<Form.Label>Status</Form.Label>
							<div className="form-check form-switch">
								<label
									className="form-check-label"
									htmlFor="status"
								>
									{formik.values.isVerified
										? "Active"
										: "Inactive"}
								</label>
								<input
									className="form-check-input"
									type="checkbox"
									role="switch"
									id="isVerified"
									disabled={data == ""}
									checked={formik.values.isVerified}
									value={formik.values.isVerified}
									name="isVerified"
									onChange={formik.handleChange}
								/>
							</div>
						</Form.Group>
					</Modal.Body>
				</div>
				<Modal.Footer className="mt-3">
					<Button
						variant="secondary"
						onClick={() => {
							modalClose();
							setImageError("")
							formik.resetForm();
						}}
					>
						Close
					</Button>
					<Button
						variant="primary"
						type="submit"
						disabled={formik.isSubmitting || isLoading}
					>
						{formik.isSubmitting || isLoading ? (
							<Fragment>
								<Spinner
									animation="border"
									size="sm"
									className="me-2"
								/>{" "}
								Please wait...
							</Fragment>
						) : (
							"Save"
						)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ModalCreateUser;
