import { toast } from "react-toastify";
import { userAuthState } from "./user.slice";
import AxiosRequest from "../../Utils/AxiosRequest";

export const login =
  (values, setLoading, navigate, setError) => async (dispatch) => {
    try {
      const { data } = await AxiosRequest.post("admin/login", values);
      localStorage.setItem("authToken", data.token);
      localStorage.setItem("user", JSON.stringify(data.data));
      dispatch(
        userAuthState([
          { key: "authToken", value: data.token },
          {
            key: "user",
            value: data.data,
          },
        ])
      );
      setLoading(false);
      toast.success("You have successfully login to dashboard!");
      navigate("/dashboard");
    } catch (err) {
      toast.error(err.response.data.message);
      setLoading(false);
    }
  };

export const changePassword =
  (values, navigate, setSubmitting, setError) => async (dispatch) => {
    try {
      const { data } = await AxiosRequest.post("admin/change-password", values);
      dispatch(
        userAuthState([
          { key: "authToken", value: null },
          { key: "user", value: null },
        ])
      );
      toast.success("Password changed successfully");

      localStorage.clear();

      navigate("/");
    } catch (err) {
      setError(err.response.data.message);
      setSubmitting(false);
      toast.error(err.message);
    }
  };

export const logout = (navigate) => async (dispatch) => {
  try {
    dispatch(
      userAuthState([
        { key: "authToken", value: null },
        { key: "user", value: null },
      ])
    );
    localStorage.clear();
    toast.success("Logged out successfully!");
    navigate("/");
  } catch (err) {
    toast.error(err.message);
  }
};

