import React from "react";

const Footer = () => {
	return (
		<footer className="position-absolute bottom-0 bg-dash-dark-2 text-white text-center py-3 w-100 text-xs" id="footer">
			<div className="container-fluid text-center">
				<p className="mb-0 text-dash-gray">2023 © BrainPulses</p>
			</div>
		</footer>
	);
};

export default Footer;
