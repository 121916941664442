import React, { useRef, useState } from "react";
import BreadCrumb from "../../components/common/BreadCrumb";
import moment from "moment";
import AxiosRequest from "../../Utils/AxiosRequest";
import { useParams } from "react-router";
import { useEffect } from "react";
import CommonTable from "../../components/common/Table";
import { FaEye } from "react-icons/fa";
import ViewQuestion from "./viewQuestion";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { getStatus, getStatusWithHtml } from "../../Utils/GetQuizStatus";

const QuizView = () => {
	const [quiz, setQuiz] = useState(null);
	const [persona, setPersona] = useState(null);
	const [quizQuestions, setQuizQuestions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [dataTotalCount, setDataTotalCount] = useState(0);
	const [showQuestionModel, setShowQuestionModel] = useState(false);
	const [questionData, setQuestionData] = useState({});
	const [searchArray, setSearchArray] = useState([]);
	const [isDisabled, setIsDisabled] = useState(false);
	const toggleRef = React.useRef(false);
	const apiCalledRef = useRef(false);
	const params = useParams();

	useEffect(() => {
		if (!apiCalledRef.current) {
			fetchQuizData();
			apiCalledRef.current = true;
		}
	}, []);

	const fetchQuizData = async () => {
		const res = await AxiosRequest.get(`/admin/quiz/${params.id}`);

		const _isActive = res?.data?.data.status === 'draft' ? false : getStatus(res?.data?.data.startDate, res?.data?.data.endDate) === 'Active' || getStatus(res?.data?.data.startDate, res?.data?.data.endDate) === 'Finished' ? true : false
		setIsDisabled(_isActive)

		setQuiz(res?.data?.data);
		const name = res?.data?.data?.persona?.map((persona) => {
			return persona?.name;
		});
		setPersona(name ? name.join(", ") : "-");
		setQuizQuestions(res?.data?.data?.questions);
		setSearchArray(res?.data?.data?.questions);
		setDataTotalCount(res?.data?.data?.questions?.length)
	};

	const columns = React.useMemo(() => [
		{
			Header: "#",
			Cell: ({ row }) => {
				return parseInt(row.id) + 1;
			},
		},
		{
			sorting: false,
			Header: "Text",
			Cell: ({ row }) => {
				return (
					row?.original?.question?.slice(0, 70) +
					(row?.original?.question?.length > 70 ? " ..." : "")
				);
			},
		},
		{
			sorting: true,
			Header: "Type",
			accessor: "type",
			Cell: ({ row }) => {
				return row?.original?.type ? row?.original?.type : '-' 
			  }
		},
		{
			sorting: true,
			Header: "Complexity",
			accessor: "complexity",
			Cell: ({ row }) => {
				return row?.original?.complexity ? row?.original?.complexity : '-' 
			  }
		},
		{
			sorting: true,
			Header: "Time",
			accessor: "time",
			Cell: ({ row }) => {
				return row?.original?.time ? `${row?.original?.time} sec` : '-' 
			  }
		},
		{
			sorting: true,
			Header: "Correct Answer",
			accessor: "correctAnswer",
			Cell: ({ row }) => {
				return row?.original?.correctAnswer ? row?.original?.correctAnswer : '-' 
			  }
		},
		{
			sorting: false,
			Header: "Action",
			Cell: ({ row }) => {
				return (
					<FaEye
						className=""
						style={{ fontSize: "20px", color: "#8a8d93" }}
						onClick={() => {
							handleViewQuestion(row?.original);
						}}
					/>
				);
			},
		},
	]);

	const handleViewQuestion = (data) => {
		setShowQuestionModel(true);
		setQuestionData(data);
	};

	const handleRoundDelete = async (round) => {
		try {
			Swal.fire({
			  icon: "error",
			  title: "Are you sure want to delete?",
			  showCancelButton: true,
			  showCloseButton: true,
			  confirmButtonColor: "#dc3545",
			  confirmButtonText: "Yes",
			  cancelButtonText: "No",
			}).then(async (result) => {
			  if (result.isConfirmed) {
				const response = await AxiosRequest.delete(`/admin/round/${round._id}`);
				if (response.data.status) {
					fetchQuizData()
				  Swal.fire({
					icon: "success",
					title: "Round has been deleted successfully!",
					confirmButtonColor: "#db6574",
				  });
				} else {
				  Swal.fire({
					icon: "error",
					title: "Error in deleting round. Please try again!",
					confirmButtonColor: "#db6574",
				  });
				}
			  }
			});
		} catch (err) {
			toast.error(err.message);
		}
	}

	const handleSearch = (searchText) => {
		const filter = searchArray?.filter((q) => {
			return q.question?.toLowerCase().includes(searchText.toLowerCase()) || q.type?.toLowerCase().includes(searchText.toLowerCase()) || q.correctAnswer?.toLowerCase().includes(searchText.toLowerCase())
		})
		setQuizQuestions(filter);
	}

	return (
		<div className="page-content form-page">
			<div className="bg-dash-dark-2 py-4">
				<div className="container-fluid d-flex align-items-center justify-content-between">
					<h3 className="mb-0">View Quiz</h3>
				</div>
			</div>
			<ViewQuestion
				show={showQuestionModel}
				modalClose={() => {
					setShowQuestionModel(false);
				}}
				data={questionData}
			/>

			<BreadCrumb page={`View Quiz - ${quiz?.title}`} />

			<section className="tables py-0 quiz-view">
				<div className="container-fluid">
					{quiz ? 
					<div className="row gy-4 mt-2">
						<div className="col-lg-12">
							<div className="card">
								<div className="card-header mt-1">
									<h3 className="quiz-header">Quiz Detail</h3>
								</div>
								<div className="card-body pt-0">
									<div className="row">
										<div className="col-md-3">
											<div className="form-group mt-3">
												<div className="form-label">
													Name
												</div>
												<div className="quiz-detail">
													{quiz?.title}
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group mt-3">
												<div className="form-label">
													Total Rounds
												</div>
												<div className="quiz-detail">
													{quiz?.totalRounds}
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group mt-3">
												<div className="form-label">
													Start Date
												</div>
												<div className="quiz-detail">
													{moment
														.utc(quiz?.startDate)
														.format(
															"Do MMMM, YYYY"
														)}
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group mt-3">
												<div className="form-label">
													End Date
												</div>
												<div className="quiz-detail">
													{quiz?.endDate
														? moment
																.utc(
																	quiz?.endDate
																)
																.format(
																	"Do MMMM, YYYY"
																)
														: "-"}
												</div>
											</div>
										</div>
									</div>
									<div className="row mt-2">
										<div className="col-md-3">
											<div className="form-group mt-3">
												<div className="form-label">
													Sponser By
												</div>
												<div className="quiz-detail">
													{quiz?.sponsor?.name}
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group mt-3">
												<div className="form-label">
													Selected Contest
												</div>
												<div className="quiz-detail">
													{persona? persona : '-'}
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group mt-3">
												<div className="form-label">
													Is Practice Quiz?
												</div>
												<div className="quiz-detail">
													{quiz?.practiceQuiz === true ? 'YES' : 'NO'}
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="form-group mt-3">
												<div className="form-label">
													Status
												</div>
												<div className="quiz-detail">
													{
														quiz?.status === "draft" ? (
															<span className="badge bg-warning">Draft</span>
														) : getStatusWithHtml(quiz?.startDate, quiz?.endDate)
													}
												</div>
											</div>
										</div>
									</div>
									<hr />
								</div>
							</div>

							{quiz?.rounds?.length > 0 ? (
								quiz?.rounds?.map((round, index) => {
									if(round.deletedAt) return
									return (
										<div className="card" key={index}>
											<div className="card-header mt-0 pt-0 d-flex justify-content-between align-items-center">
												<h3 className="quiz-header">
													Round Detail{" "}
													{`(${index + 1} / ${
														quiz?.totalRounds
													})`}
												</h3>
												{!isDisabled ? <button className="btn btn-sm btn-danger text-center" 
													onClick={() => {   
														handleRoundDelete(round);
													}} >
													<MdDelete style={{ fontSize: "20px", color: "#eef3ff", marginTop: "-4px" }} /> Delete Round
												</button> : null}
											</div>
											<div className="card-body pt-0">
												<div className="row">
													<div className="col-md-3">
														<div className="form-group mt-3">
															<div className="form-label">
																Name
															</div>
															<div className="quiz-detail">
																{round?.title}
															</div>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-group mt-3">
															<div className="form-label">
																No. of Attempts
															</div>
															<div className="quiz-detail">
																{round?.noOfTry ? round?.noOfTry : '-'}
															</div>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-group mt-3">
															<div className="form-label">
																Complexity Level
															</div>
															<div className="quiz-detail">
																{
																	round?.complexity
																}
															</div>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-group mt-3">
															<div className="form-label">
																Type
															</div>
															<div className="quiz-detail">
																{round?.type}
															</div>
														</div>
													</div>
												</div>
												<div className="row mt-2">
													<div className="col-md-3">
														<div className="form-group mt-3">
															<div className="form-label">
																Passing Criteria
															</div>
															<div className="quiz-detail">
																{
																	round?.passingMarks
																}
																%
															</div>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-group mt-3">
															<div className="form-label">
																Total Questions
															</div>
															<div className="quiz-detail">
																{
																	round?.totalQuestions
																}
															</div>
														</div>
													</div>
													<div className="col-md-5">
														<div className="form-group mt-3">
															<div className="form-label">
																Description
															</div>
															<div className="quiz-description">
																{
																	round?.description
																}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})
							) : (
								<div className="card">
									<div className="card-header mt-0 pt-0">
										<h3 className="quiz-header">
											Round Details
										</h3>
									</div>
									<div className="card-body pt-0">
										<p className="text-center">
											No rounds are added for this quiz
										</p>
									</div>
								</div>
							)}
							<div className="card">
								<div className="card-body pt-0">
									<hr />
								</div>
							</div>
							<div className="card">
								<div className="card-header mt-0 pt-0">
									<h3 className="quiz-header">
										Quiz Questions
									</h3>
								</div>
								<div className="card-body pt-0">
									<div className="row">
										<div className="col-md-4">
											<label className="form-label pt-2">&nbsp;&nbsp;</label>
											<input
												type="text"
												placeholder="Search"
												className="form-control "
												aria-label="Search"
												onChange={(e) => {
													handleSearch(e.target.value)
													toggleRef.current = true;
												}}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<CommonTable
											columns={columns}
											data={quizQuestions}
											loading={loading}
											manualPagination={false}
											manualSort={false}
											// defaultSortColumn={"question"}
											toggleRef={toggleRef}
											dataTotalCount={dataTotalCount}
										/>
									</div>
								</div>
							</div>
						</div>
					</div> : 
					<div className="d-flex justify-content-center" style={{marginTop: "230px"}}>
						<div className="page_loader" />
					</div>
					}
				</div>
			</section>
		</div>
	);
};

export default QuizView;
