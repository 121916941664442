import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { logout } from "../store/user/user.fetch";
import { NavLink } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { Dropdown, NavDropdown } from "react-bootstrap";
import { ImKey } from "react-icons/im";
import { CgProfile } from "react-icons/cg";
import ChangePasswordModel from "../components/user/ChangePasswordModel";
// import bizwiz from "../assets/img/bizwiz.png";
import bizwiz from "../assets/img/logo_rm_nxg.png";
import defaultUser from "../assets/img/default-user.png";
import ViewAdminProfile from "../components/user/ViewAdminProfile";
import "../assets/font/fontAptos.css";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const [showCreatePasswordModel, setShowCreatePasswordModel] = useState(false);
  const [showProfileModel, setShowProfileModel] = useState(false);

  return (
    <header className="header">
      {/* py-3 */}
      <nav className="navbar navbar-expand-lg  bg-dash-dark-2 border-bottom border-dash-dark-1 z-index-10">
        <div className="container-fluid d-flex align-items-center justify-content-between py-1">
          <div className="navbar-header d-flex align-items-center">
            <NavLink
              className="navbar-brand text-uppercase text-reset d-flex"
              to="/"
            >
              <img
                src={bizwiz}
                alt="BrainPulses"
                style={{
                  // width: "40px",
                  height: "55px",
                }}
              />
              {/* <div className="brand-text brand-big ms-2 fontAptos" style={{textTransform: "none"}}>
                <strong className="text-primary">Brain</strong>
                <strong>Pulses</strong>
                <h1 className=" fw-bold fontAptos" style={{fontSize: "12px"}}>Powered by NextgenInnov8</h1>
              </div> */}
            </NavLink>
          </div>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                variant="dark"
                className="dropdown-menu-button"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <img
                  style={{
                    width: "30px",
                    height: "30px",
                    marginTop: "-10px",
                  }}
                  className="avatar rounded-circle"
                  src={defaultUser}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  backgroundColor: "#2d3035",
                  width: "80px",
                }}
              >
                <Dropdown.Item
                  className="text-white dropdown-menu-item"
                  onClick={() => {
                    setShowProfileModel(true);
                  }}
                >
                  <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                    <CgProfile />
                  </svg>
                  <span style={{ marginLeft: "10px" }}>View Profile</span>
                </Dropdown.Item>

                <Dropdown.Item
                  className="text-white dropdown-menu-item"
                  onClick={() => {
                    setShowCreatePasswordModel(true);
                  }}
                >
                  <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                    <ImKey />
                  </svg>
                  <span style={{ marginLeft: "10px" }}>Change Password</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-white dropdown-menu-item"
                  onClick={() => {
                    dispatch(logout(navigate));
                  }}
                >
                  <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                    <use xlinkHref="#disable-1"> </use>
                  </svg>
                  <span style={{ marginLeft: "10px" }}>Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* <ul className="list-inline mb-0">
						<li className="list-inline-item logout px-lg-2"></li>
						<li className="list-inline-item logout px-lg-2">
							<a
								className="text-sm text-reset px-1 px-lg-0"
								id="logout"
								onClick={() => {
									dispatch(logout(navigate));
								}}
							>
								<span
									className="d-none d-sm-inline-block"
									style={{ marginRight: "10px" }}
								>
									Logout
								</span>
								<svg className="svg-icon svg-icon-xs svg-icon-heavy">
									<use xlinkHref="#disable-1"> </use>
								</svg>
							</a>
						</li>
					</ul> */}
        </div>
        <ChangePasswordModel
          show={showCreatePasswordModel}
          modalClose={() => {
            setShowCreatePasswordModel(false);
          }}
        />
        <ViewAdminProfile 
          show={showProfileModel}
          data={user}
          modalClose={() => {
            setShowProfileModel(false)
          }}
        />
      </nav>
    </header>
  );
};
export default Header;
