import React, { useCallback, useRef, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import ModalCreateUser from "../../components/user/UserModel";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CommonTable from "../../components/common/Table";
import AxiosRequest from "../../Utils/AxiosRequest";
import { useDebounce } from "../../hooks/useDebounce";
import BreadCrumb from "../../components/common/BreadCrumb";
import { toast } from "react-toastify";

const Users = () => {
	const [showUserModel, setUserModel] = useState(false);
	const [showEditUserModel, setShowEditUserModel] = useState(false);
	const [editUserData, setEditUserData] = useState({});
	const [selectedUser, setSelectedUser] = useState([]);
	const [loading, setLoading] = useState(false);
	const [userData, setUserData] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [dataTotalCount, setDataTotalCount] = useState(0);
	const [searchText, setSearchText] = useState("");
	const fetchIdRef = useRef(0);
	const debounceSearch = useDebounce(searchText, 500);
	const [pageData, setPageData] = useState({
		pageSize: 10,
		pageIndex: 0,
		sortBy: [],
	});

	const toggleRef = React.useRef(false);

	const handleEditUser = (data) => {
		try {
			setShowEditUserModel(true);
			setEditUserData(data);
		} catch (err) {
			console.log("err", err);
		}
	};

	const fetchData = useCallback(
		async ({ pageSize, pageIndex, sortBy }) => {
			const fetchId = ++fetchIdRef.current;
			const sortColumn = sortBy[0]?.id;
			const sortOrder = sortBy[0]?.desc ? "desc" : "asc";
			setLoading(true);
			if (searchText) {
				pageIndex = 0;
			}
			setPageData({
				pageSize: pageSize,
				pageIndex: pageIndex,
				sortBy: sortBy,
			});
			if (fetchId === fetchIdRef.current) {
				const res = await AxiosRequest(
					`/admin/user?limit=${pageSize}&page=${
						pageIndex + 1
					}&search=${searchText}&sortBy=${sortColumn}&sortAt=${sortOrder}`
				);
				setUserData(res?.data?.data?.data || []);
				setPageCount(Math.ceil(res?.data?.data.totalCount / pageSize));
				setDataTotalCount(res?.data?.data.totalCount)
				setLoading(false);
			}
		},
		[debounceSearch, searchText]
	);
	
	const columns = React.useMemo(
		() => [
			{
				Header: "#",
				Cell: ({ row }) => {
					return row.index + 1;
				},
			},
			{
				sorting: true,
				Header: "Name",
				accessor: "name",
			},
			{
				sorting: true,
				Header: "Email",
				accessor: "email",
				Cell: ({ row }) => {
					return row?.original?.email ? row?.original?.email : "-";
				},
			},
			{
				sorting: true,
				Header: "Mobile No.",
				accessor: "mobileNumber",
				Cell: ({ row }) => {
					return row?.original?.mobileNumber
						? row?.original?.mobileNumber
						: "-";
				},
			},
			{
				sorting: true,
				Header: "City",
				accessor: "city",
				Cell: ({ row }) => {
					return row?.original?.city ? row?.original?.city : "-";
				},
			},
			{
				sorting: true,
				Header: "Educational Insititute",
				// accessor: "institute.name",
				Cell: ({ row }) => {
					return row?.original?.institute?.name
						? row?.original?.institute?.name?.slice(0, 50) +
                        (row?.original?.institute?.name?.length > 50 ? " ..." : "")
						: "-";
				},
			},
			{
				sorting: true,
				Header: "Contest",
				accessor: "persona.name",
			},
			{
				Header: "Status",
				Cell: ({ row }) => {
					return row?.original?.deletedAt ? (
						<span className="badge bg-danger">Deleted</span>
					) : row?.original?.isVerified ? (
						<span className="badge bg-success">Active</span>
					) : (
						<span className="badge bg-dark">InActive</span>
					);
				},
			},
			{
				Header: "Action",
				Cell: ({ row }) => {
					return (
						<div className="d-flex justify-start">
							<FaUserEdit
								style={{
									fontSize: "20px",
									color: "#7385C9",
									cursor: "pointer",
								}}
								onClick={() => {
									handleEditUser(row?.original);
								}}
							/>
							{!row?.original?.deletedAt ? (
								<MdDelete
									style={{
										fontSize: "20px",
										color: "#BD5357",
										marginLeft: "7px",
										cursor: "pointer",
									}}
									onClick={() => {
										handleDeleteUser(row?.original);
									}}
								/>
							) : null}
						</div>
					);
				},
			},
		],
		[]
	);

	const handleDeleteUser = (data) => {
		try {
			Swal.fire({
				icon: "error",
				title: 'Are you sure?',
  				text: "You want to delete this user!",
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: "#dc3545",
				confirmButtonText: "Yes",
				cancelButtonText: "No",
			}).then(async (result) => {
				if (result.isConfirmed) {
					const response = await AxiosRequest.delete(
						`/admin/user/${data._id}`
					);
					fetchData(pageData);
					if (response.data.status) {
						Swal.fire({
							icon: "success",
							title: "User has been deleted successfully!",
							confirmButtonColor: "#8d65db",
						});
					} else {
						Swal.fire({
							icon: "error",
							title: "Error in deleting user. Please try again!",
							confirmButtonColor: "#8d65db",
						});
					}
				}
			});
		} catch (err) {
			toast.error(err.message);
		}
	};

	const handleMultipleDeleteUsers = (data) => {
		const ids = data.map((item) => {
			return item?.original?._id;
		});

		try {
			Swal.fire({
				icon: "error",
				title: 'Are you sure?',
				text: "You want to delete selected users?",
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: "#dc3545",
				confirmButtonText: "Yes",
				cancelButtonText: "No",
			}).then(async (result) => {
				if (result.isConfirmed) {
					const response = await AxiosRequest.post(
						`/admin/user/delete-multiple`,
						{ ids }
					);

					fetchData(pageData);

					if (response.data.status) {
						Swal.fire({
							icon: "success",
							title: "Selected users are deleted successfully!",
							confirmButtonColor: "#8d65db",
						});
					} else {
						Swal.fire({
							icon: "error",
							title: "Error in deleting users. Please try again!",
							confirmButtonColor: "#8d65db",
						});
					}
				}
			});
		} catch (err) {
			toast.error(err.message);
		}
	};

	return (
		<div className="page-content form-page">
			<div className="bg-dash-dark-2 py-4">
				<div className="container-fluid d-flex align-items-center justify-content-between">
					<h3 className="mb-0">Users</h3>
					<button
						className="btn btn-primary ms-3"
						onClick={() => {
							setUserModel(true);
						}}
					>
						Add User
					</button>
				</div>
			</div>

			<ModalCreateUser
				show={showUserModel}
				modalClose={() => {
					setUserModel(false);
				}}
				title="Create User"
				pageData={pageData}
				fetchData={fetchData}
			/>
			<ModalCreateUser
				show={showEditUserModel}
				modalClose={() => {
					setShowEditUserModel(false);
				}}
				data={editUserData}
				title="Edit User"
				pageData={pageData}
				fetchData={fetchData}
			/>

			<BreadCrumb page="Users" />

			<section className="tables py-0">
				<div className="container-fluid">
					<div className="row flex align-items-end">
						<div className="col-md-4">
							<label className="form-label pt-2">
								&nbsp;&nbsp;
							</label>
							<input
								type="text"
								placeholder="Search"
								className="form-control "
								aria-label="Search"
								onChange={(e) => {
									setSearchText(e.target.value);
									toggleRef.current = true;
								}}
							/>
						</div>
						<div className="col-md-3"></div>
						<div className="col-md-3"></div>
						<div className="col-md-2 text-end">
							<button
								disabled={!selectedUser.length}
								className={`pt-2 ${!selectedUser.length ? 'btn btn-dark ' : 'btn btn-danger'}`}
								onClick={() => {
									handleMultipleDeleteUsers(selectedUser);
								}}
							>
								Delete Users
							</button>
						</div>
					</div>
					<div className="row gy-4 mt-2">
						<div className="col-lg-12">
							<div className="card mb-0">
								<div className="card-header"></div>
								<div className="card-body pt-0">
									<div className="table-responsive">
										<CommonTable
											columns={columns}
											data={userData}
											fetchData={fetchData}
											loading={loading}
											pageCount={pageCount}
											manualPagination={true}
											manualSort={true}
											// defaultSortColumn={"name"}
											setSelectedRows={setSelectedUser}
											isSelection={true}
											toggleRef={toggleRef}
											dataTotalCount={dataTotalCount}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Users;
