import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
const BarChart = ({
	dataLabels,
	dataSet,
	title,
	tooltipLabel,
	backgroundColor = "#065082",
    color="#eef3ff"
}) => {
	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend
	);

    console.log(dataLabels)
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
                labels: {
                    color: '#EEF3FF'
                }
			},
			title: {
				display: true,
				text: title,
                color: "#EEF3FF"
			},
		},
		elements: {
			bar: {
				barPercentage: 0.5,
				categoryPercentage: 0.9,
			},
		},
        scales: {
            y: {
              ticks: { 
				color: '#eef3ff', 
				beginAtZero: true,
				min: 0,
				stepSize: 5,
			}
            },
            x: {
              ticks: { color: '#eef3ff', beginAtZero: true }
            }
        }
	};
	const chartData = {
		labels: dataLabels,
        color: "#fff",
		datasets: [
			{
				label: tooltipLabel,
				data: dataSet,
				backgroundColor: backgroundColor,
			},
		],
	};
	return (
		<div className="rounded">
			<Bar data={chartData} options={options} />
		</div>
	);
};
export default BarChart;
