import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const quizSlice = createSlice({
  name: "quiz",
  initialState: {
    quizDetail: {},
    rounds: [],
    questions: [],
  },
  reducers: {
    quizState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { quizState } = quizSlice.actions;

export default quizSlice.reducer;
