import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { changePassword } from "../../store/user/user.fetch";
import { useNavigate } from "react-router-dom";

const ChangePasswordModel = ({
  show,
  modalClose,
  data = "",
  title = "Change Password",
}) => {
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Old password is required"),
    password: Yup.string().required("Password is required"),
    confirm_password: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Password and confirm password must be same"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (value, { setSubmitting }) => {
      setSubmitting(true);
      setError("");
      dispatch(
        changePassword(
          {
            passwordCurrent: value.old_password,
            passwordConfirm: value.confirm_password,
            password: value.password,
          },
          navigate,
          setSubmitting,
          setError
        )
      );
      setSubmitting(false);
    },
  });

  useEffect(() => {
  }, [formik.isSubmitting]);
  return (
    <Modal size="lg" show={show} onHide={modalClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <div className="d-flex justify-content-center">
          <Modal.Body>
            <Form.Group className="form-group mt-3" controlId="formBasicEmail">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter old password"
                autoComplete="off"
                name="old_password"
                value={formik.values.old_password}
                onChange={formik.handleChange}
                className="form-control mt-1"
              />
              {formik.errors.old_password && formik.touched.old_password ? (
                <Form.Text className="text-danger">
                  {formik.errors.old_password}
                </Form.Text>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group mt-3" controlId="formBasicEmail">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                autoComplete="off"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                className="form-control mt-1"
              />
              {formik.errors.password && formik.touched.password ? (
                <Form.Text className="text-danger">
                  {formik.errors.password}
                </Form.Text>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group mt-3" controlId="formBasicEmail">
              <Form.Label>Confrim Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter confirm new password"
                autoComplete="off"
                name="confirm_password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                className="form-control mt-1"
              />
              {formik.errors.confirm_password &&
              formik.touched.confirm_password ? (
                <Form.Text className="text-danger">
                  {formik.errors.confirm_password}
                </Form.Text>
              ) : null}
            </Form.Group>
            {error && <div className="text-danger">{error}</div>}
          </Modal.Body>
        </div>
        <Modal.Footer className="mt-3">
          <Button variant="secondary" onClick={modalClose}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              <Fragment>
                <Spinner animation="border" size="sm" className="me-2" /> Please
                wait...
              </Fragment>
            ) : (
              "Change Password"
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModel;
