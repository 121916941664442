import React, { useEffect } from "react";
import Swal from "sweetalert2";

const Activation = () => {
	useEffect(() => {
		Swal.fire({
			title: "Dear Admin,",
			text: "Are you sure you want to activate this user?",
			icon: "warning",
			confirmButtonText: "Yes",
			showCancelButton: true,
			showCloseButton: true,
			cancelButtonText: "No",
		}).then(async (result) => {
            if (result.isConfirmed) {
            }
        });
	}, []);
	return null;
};

export default Activation;
