import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import AxiosRequest from "../../Utils/AxiosRequest";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { uploadImageToAzure } from "../../Utils/ImageUpload";
import { FaTimesCircle } from "react-icons/fa";
import { Image } from "react-bootstrap";

const ModalCreatePersona = ({
	show,
	modalClose,
	data = "",
	title = "Create Contest",
	pageData,
	fetchData,
}) => {
	const validationSchema = Yup.object().shape({
		name: Yup.string().min(5, "Please enter min 5 characters long name!").required("Contest name is required"),
		description: Yup.string().max(500, "You are allow to add only 500 character's long description").required("Description is required"),
	});

	const [initialValues, setInitialValues] = useState({
		name: "",
		description: "",
		videoUrl: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [imageError, setImageError] = useState("");

	useEffect(() => {
		formik.resetForm();
		if (data) {
			setInitialValues({
				name: data.name,
				description: data.description,
				videoUrl: data.videoUrl,
			});
		}
	}, [data]);

	const handleImageUpload = async (e) => {
		setIsLoading(true);
		setImageError("");

		const supportedFormats = ['video/mp4', 'video/quicktime', 'video/webm', 'video/mpeg', 'video/ogg', 'image/gif'];
		
		if(supportedFormats.includes(e.target.files[0].type)) {  
			const url = await uploadImageToAzure(e.target.files[0]);
			formik.setFieldValue("videoUrl", url);
			setIsLoading(false);
		}else {
			formik.setFieldValue("videoUrl", "");
			setImageError("Only gif/video type files are allowed");
			setIsLoading(false);
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (value, { setSubmitting }) => {
			setSubmitting(true);

			try {
				if (data) {
					const response = await AxiosRequest.patch(
						`/admin/persona/${data._id}`,
						{
							...value,
							// videoUrl: "https://i.ibb.co/WtsvWbx/Travis-Kelce-goat.png",
						}
					);

					if (response.data.status) {
						fetchData(pageData);
						modalClose();
						setSubmitting(false);
						toast.success("Contest updated successfully!");
						formik.resetForm();
						setImageError("")
					} else {
						toast.error(
							"Error in updating contest. Please try again!"
						);
					}
				} else {
					const response = await AxiosRequest.post("/admin/persona", {
						...value,
					});
					if (response.data.status) {
						fetchData(pageData);
						modalClose();
						setSubmitting(false);
						toast.success("Contest created successfully!");
						formik.resetForm();
						setImageError("")
					} else {
						toast.error(
							"Error in creating contest. Please try again!"
						);
					}
				}
			} catch (err) {
				toast.error(err.message);
				setSubmitting(false);
			}
		},
	});
	return (
		<Modal size="lg" show={show} onHide={modalClose}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={formik.handleSubmit}>
				<div className="d-flex justify-content-center"  style={{ overflowY: 'auto', height: '500px' }}>
					<Modal.Body>
						<Form.Group
							className="form-group mt-3"
							controlId="formBasicName"
						>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter name"
								autoComplete="off"
								name="name"
								value={formik.values.name}
								onChange={formik.handleChange}
								className="form-control mt-1"
							/>
							{formik.errors.name && formik.touched.name ? (
								<Form.Text className="text-danger validation-message">
									{formik.errors.name}
								</Form.Text>
							) : null}
						</Form.Group>
						<Form.Group
							className="form-group mt-3"
							controlId="formBasicDescription"
						>
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								placeholder="Enter description"
								autoComplete="off"
								name="description"
								value={formik.values.description}
								onChange={formik.handleChange}
								className="form-control mt-1"
								rows={5}
								cols={10}
							/>
							{formik.errors.description &&
							formik.touched.description ? (
								<Form.Text className="text-danger validation-message">
									{formik.errors.description}
								</Form.Text>
							) : null}
						</Form.Group>
						<Form.Group
							controlId="formLogoImage"
							className="form-group mt-3"
						>
							<Form.Label>GIF or Video</Form.Label>
							{/* <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                handleImageUpload(e);
                                }}
                            /> */}
							<Form.Control
								type="file"
								accept="video/*"
								onChange={(e) => {
									handleImageUpload(e);
								}}
							/>
							{imageError && (
								<Form.Text className="text-danger validation-message">
									{imageError}
								</Form.Text>
							)}
							
							{formik.values?.videoUrl &&
								(formik.values.videoUrl.includes("mp4") ? (
									<div
										className="position-relative mt-3"
										style={{
											width: "200px",
											height: "auto",
										}}
									>
										<video autoPlay width="200px">
											<source
												src={formik.values.videoUrl}
												type="video/mp4"
											/>
										</video>
										<FaTimesCircle
											className="position-absolute top-0 end-0  cursor-pointer"
											style={{marginRight: "-283px",marginTop: "-4px"}}
											onClick={() => {
												formik.setFieldValue(
													"videoUrl",
													""
												);
											}}
										/>
									</div>
								) : (
									<div
										className="position-relative mt-3"
										style={{
											width: "200px",
											height: "200px",
										}}
									>
										<Image
											src={formik.values.videoUrl}
											fluid
											alt="Preview"
											sizes="sm"
										/>
										<FaTimesCircle
											className="position-absolute top-0 end-0 mt-2 me-2 cursor-pointer"
											onClick={() => {
												formik.setFieldValue(
													"videoUrl",
													""
												);
											}}
										/>
									</div>
								))}
						</Form.Group>
					</Modal.Body>
				</div>
				<Modal.Footer className="mt-3">
					<Button variant="secondary" onClick={() => {
							modalClose();
							formik.resetForm();
							setImageError("")
						}}>
						Close
					</Button>
					<Button
						variant="primary"
						type="submit"
						disabled={formik.isSubmitting || isLoading}
					>
						{formik.isSubmitting || isLoading ? (
							<Fragment>
								<Spinner
									animation="border"
									size="sm"
									className="me-2"
								/>{" "}
								Please wait...
							</Fragment>
						) : (
							"Save"
						)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ModalCreatePersona;
