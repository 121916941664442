import moment from "moment";

export const getStatus = (_startDate, _endDate) => {
    const currentDate = moment.utc().format();
    const startDate = moment.utc(_startDate);
    const endDate = _endDate ? moment.utc(_endDate) : null;

    // // Set hours, minutes, seconds, and milliseconds to 0
    // currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // if (endDate) {
    //     endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // }

    if (
        (startDate.isSameOrBefore(currentDate) &&
            endDate &&
            endDate.isSameOrAfter(currentDate)) ||
        (startDate.isBefore(currentDate) && !_endDate)
    ) {
        return "Active";
    } else if (
        startDate.isSameOrBefore(currentDate) &&
        endDate &&
        endDate.isSameOrBefore(currentDate)
    ) {
        return "Finished";
    } else {
        return "Inactive";
    }
};

export const getStatusWithHtml = (_startDate, _endDate) => {
    const currentDate = moment.utc().format();
    const startDate = moment.utc(_startDate);
    const endDate = _endDate ? moment.utc(_endDate) : null;

    // Set hours, minutes, seconds, and milliseconds to 0
    // currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // if (endDate) {
    //     endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    // }

    // console.log("startDate = ", startDate);
    // console.log("endDate = ", endDate);
    // console.log("currentDate = ", currentDate);

    // console.log(
    //     "(startDate.isSameOrBefore(currentDate) && (endDate && endDate.isSameOrAfter(currentDate))) = ",
    //     startDate.isSameOrBefore(currentDate) &&
    //         endDate &&
    //         endDate.isSameOrAfter(currentDate)
    // );

    // console.log("(startDate.isBefore(currentDate) && !_endDate = ", (startDate.isBefore(currentDate) && !_endDate));

    if (
        (startDate.isSameOrBefore(currentDate) &&
            endDate &&
            endDate.isSameOrAfter(currentDate)) ||
        (startDate.isBefore(currentDate) && !_endDate)
    ) {
        return <span className="badge bg-success">Active</span>;
    } else if (
        startDate.isSameOrBefore(currentDate) &&
        endDate &&
        endDate.isSameOrBefore(currentDate)
    ) {
        return <span className="badge bg-danger">Finished</span>;
    } else {
        return <span className="badge bg-dark">Inactive</span>;
    }
};
